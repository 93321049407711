
const mainstyle={
    primaryButton:{
        backgroundColor:"#6e2fff",
        color:"white",
        height:'100%',
        fontWeight:600,
    }
    
}
export {mainstyle};