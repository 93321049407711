
import cls from './index.module.css';
function HeaderBanner(props) {
  return (
    <div className={`row mx-0 ${cls['bg-light-blue']}`}>
      <div className="col-md-6 d-flex align-items-center">
        <div className="ps-md-5">
          <h2 className={`${cls['title-main']} ${cls['font-extra-bold']} ps-md-3`}>
           {props.title}
          </h2>
          <h4 className='ps-md-3'>
            {props.description}
          </h4>
        </div>
      </div>
      <div className="col-md-6 text-center">
        <img src={props.image} className="img-fluid w-75"></img>
      </div>
    </div>
  );
}

export default HeaderBanner;
