import React from 'react'
import { Link } from 'react-router-dom'
import CommentSection from '../Confiq/CommentBox'
import LoveButton from '../Confiq/Lovebutton'
import ShareButton from '../Confiq/ShareButton'
import Footer from '../components/Footer/Footer'
import BlogFifth from './BlogFifth'
import Blogfourth from './Blogfourth'
import BlogLast from './BlogLast'
import './Blogmain.css'
import Blognavbar from './Blognavbar'
import Blogsecond from './Blogsecond'
import Blogthird from './Blogthird'




function Blogmain() {
  return (
    <>
    <Blognavbar/>
    <div className='blogmain-first'>
        <div className='start-image-text'>
            <div className='header-image'>
            </div>
            <div className='HeaderImageTexts'>
                <div className='FirstHeadingBlog'>Semikolen Header</div>
                <div>The term "semikolon" (semicolon in English) is a punctuation mark
                that is used in writing to separate two closely related independent clauses in a sentence.
                An independent clause is a group of words that can stand alone as a complete sentence and express a complete thought. 
                When two independent clauses are closely related and the writer wants to link them together, a semicolon can be used 
                instead of a period to create a more cohesive sentence.
                For example: "I have a big presentation tomorrow; I need to prepare my slides tonight."
                The semicolon in this sentence connects the two independent clauses and indicates to the reader that
                there is a relationship between the two thoughts. It is important to note that semicolons are not interchangeable
                commas or periods, and their usage requires a clear understanding of sentence structure and proper punctuation rules.
                </div>
                <div className='LikeShareComment'>
                     <LoveButton/>
                <div className='comment-div'>
                    <CommentSection/>
                </div>
                <div className='share-div'>
                <ShareButton/>
                </div>
                </div>
            </div>
        </div>
        <div className='header-linking-texts'>
            <Link to='/BlogSeconPage' className='texts-div' >
                Half Colon
                
            </Link>
            <Link to='/BlogSeconPage' className='texts-div'>
                Wink Colon
            </Link>
            <Link to='/BlogSeconPage' className='texts-div'>
                Hybrid Comma
                
            </Link>
            <Link to='/BlogSeconPage' className='texts-div'>
                Supra Comma
            </Link>
            </div> 
    </div>
    <Blogsecond/>
    <Blogthird/>
    <Blogfourth/>
    <BlogFifth/>
    <BlogLast/>
    <Footer/>
    </>
  )
}

export default Blogmain