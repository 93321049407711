import React from 'react';
import './Thirdpage.css';
import student from '../../../Assets/images/icon dev career (1).svg';
import teacher from '../../../Assets/images/icon dev career (2).svg';
import chat from '../../../Assets/images/icon dev career (3).svg';
import cat from '../../../Assets/images/icon dev career (4).svg';


function Thirdpage() {
  return (
    <div className='Thirdpage-Starting'>
        <div className='heading-thirdpage'>
            Supercharge Your Developer Career
        </div>
        <div className='secondtext-thirdpage'>
            Build software projects like the top 1% developers and learn all the skills<br/>
            you need to land the best full stack developer jobs.
        </div>
        <div className='four-boxes'>
        <div className='four-boxes-div'>
            <div className='first-box'>
                <div className='inside-div'>
                    <img src={student}/>
                </div>
                <div className='learn-text'>
                        Learn by Working like<br/>
                        Professional developers
                    </div>
                    <div className='big-text'>
                        Learn Full-Stack development or<br/>
                        Backend development through <br/>
                        an immersive project-based<br/>
                        curriculum focused on practical<br/>
                        developer skills and real-world<br/>
                        scenarios.

                    </div>
                

                    </div>
                    <div className='second-box'>
                    <div className='inside-div'>
                    <img src={teacher}/>
                    </div>
                    <div className='learn-text-second'>
                        Learn From Seasoned<br/>
                        Industry Professionals in<br/>
                        Real Time
                    </div>
                        <div className='big-text'>
                        Participate in activity-based live<br/>
                        working sessions facilitated by<br/>
                        industry mentors from<br/>
                        companies like Microsoft,<br/>
                        Flipkart,Nilenso.
                        </div>

                        </div>
                        <div className='third-box'>
                            <div className='inside-div'>
                            <img src={cat}/>
                            </div>
                            <div className='learn-text-third'>
                        Build a GitHub portfolio that<br/>
                        impresses recruiters
                        
                    </div>
                        <div className='big-text'>
                        Participate in activity-based live<br/>
                        working sessions facilitated by<br/>
                        industry mentors from<br/>
                        companies like Microsoft,<br/>
                        Flipkart,Nilenso.
                        </div>
                

                            </div>
                        <div className='fourth-box'>
                        <div className='inside-div'>
                        <img src={chat}/>
                        </div>
                        <div className='learn-text-fourth'>
                        Stand out at interviews with<br/>
                        real proof of work
                        
                    </div>
                        <div className='big-text'>
                        Validate your learning and let<br/>
                        your skills shine with a work<br/>
                        experience certificate that<br/>
                        makes you stand out at<br/>
                        interviews.
                        </div>
                
                

                    </div>
        </div>
    </div>

    </div>
  )
}

export default Thirdpage;