import { Col, Row } from "antd";
import { BiLogoInstagram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { LiaWhatsapp } from "react-icons/lia";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { RiFacebookCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/footerlogosemikolen.svg";
import "./styles.scss";

const Footer = () => {
  const navigation = useNavigate();

  return (
    <div className="footer-container">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6}>
          <img className="footer-logo" src={Logo} />
          <div className="ft-logoTxt">
            © 2023 Bairuhatech, Inc. <br />
            All rights reserved
          </div>
        </Col>

        <Col xs={24} sm={12} md={4}>
          <div className="list-container">
            <div className="list-heading">COURCES</div>
            <ul>
              <li>Full stack development</li>
              <li>Mobile App development </li>
              <li>Web Designing</li>
              <li>Backend Development</li>
              <li>Software testing</li>
              <li>Prompt Engineering with AI</li>
              <li>Devops</li>
              <li>Kids coding </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={12} md={5}>
          <div className="list-container commonlist">
            <div className="list-heading">SEMIKOLEN</div>
            <ul>
              <li
                onClick={() => {
                  navigation("/about");
                  window.scrollTo(0, 0);
                }}
              >
                About Us
              </li>
              <li>Semikolen Help</li>
              <li>Best Price</li>
              <li>Careers</li>
              <li>Solution Bar</li>
              <li>Terms and Conditions</li>
              <li>Privacy policy</li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <div className="list-container commonlist">
            <div className="list-heading">EXPLORE</div>
            <ul>
              <li>Apps</li>
              <li onClick={() => navigation('hireAdeveloper')}>Hire a Developer</li>
              <li>Showcase</li>
              <li>Whatsapp Us</li>
              <li>+91 82815 08005</li>
              <li>Tell Us More</li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={12} md={5}>
          <div className="list-container commonlist">
            <div className="list-heading">SOCIAL</div>
            <div className="social-Icons">
              <a href="https://www.facebook.com/profile.php?id=100090563829993">
              <RiFacebookCircleLine size={25} color="#a9bdf1" />
              </a>
              <a href="https://www.instagram.com/semikolen_/">
                <BiLogoInstagram size={25} color="#a9bdf1" />
              </a>
              {/* <FaXTwitter size={22} color="#a9bdf1" /> */}
              {/* <a href="https://www.youtube.com/@Bairuhatech">
                <PiYoutubeLogoLight size={24} color="#a9bdf1" />
              </a> */}
              <a href="https://wa.me/+918281508005">
                <LiaWhatsapp size={24} color="#a9bdf1" />
              </a>
            </div>
          </div>
          <div className="list-container commonlist">
            <div className="list-heading">Contact Us</div>
            <ul>
              <li>
              <a href="mailto:mail@semikolen.com">mail@semikolen.com</a>
              </li>
              <li>
                <a href="https://www.google.com/maps/place/bairuha+tech/@11.4826539,75.9918744,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba66deaf70c83cd:0x6cca385417bef6b5!8m2!3d11.4826487!4d75.9944493!16s%2Fg%2F11scnpg9_q?entry=ttu">
                  Puthuppady, Calicut, Kerala <br/> India - 673586
                </a>
              </li>
              <li>
                <li>
                  <a href="tel:+918281508005">+91 82815 08005</a>
                </li>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
