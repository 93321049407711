import React from "react";
import { Input, Button, Form, Select } from "antd";
import PhoneInput from "react-phone-number-input";

const TabletSecondPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="headerSignIn">
        <h2>Join with us...</h2>
        <h6>
          Submit your queries here and we <br />
          will get to you as soon as
          <br />
          possible
        </h6>
      </div>
      <div className="servicesDivSignIn d-none d-sm-flex d-lg-none w-50">
        {/* <TermsAndConditions /> */}

        <div className="tabletview">
          <Form>
            <Form.Item name="course" label="Highest Education">
              <Select
                style={{ width: "100%" }}
                placeholder="Type Your Highest Education"
                // onChange={handleStateChange}
              >
                {/* Your Select options */}
              </Select>
            </Form.Item>

            <Form.Item name="name" label="Relevent Education">
              <Input placeholder="Type your Relevent Education." />
            </Form.Item>

            <Form.Item name="qualification" label="Educational Qualification">
              <Input placeholder="Type your College or School Name" />
            </Form.Item>

            <Form.Item name="country" label="Language">
              <Input placeholder="Type Your Language" />
            </Form.Item>

            <Form.Item name="city" label="Year Of Completed">
              <Input placeholder="Type your Year Of Completed" />
            </Form.Item>

            <Form.Item name="Select Your " label="Education">
              <Input placeholder="Type Your Education" />
            </Form.Item>

            <Form.Item name="Select Your " label="Select Your Course">
              <Input placeholder="Type Your Select Your Course" />
            </Form.Item>
            <Form.Item name="Select Your " label="Preferable Duration">
              <Input placeholder="Type Your Preferable Duration" />
            </Form.Item>
            <Form.Item name="Select Your " label="Online or Ofline">
              <Input placeholder="Type Your Online or Ofline" />
            </Form.Item>
            <Form.Item name="Select Your " label="Start Date">
              <Input placeholder="Type Your Start Date" />
            </Form.Item>

            {/* <Form.Item name="mobileNumber" label="Mobile Number">
              <PhoneInput placeholder="XXXX XXX XXX" defaultCountry="IN" />
            </Form.Item> */}

            <Form.Item>
              <Button
                className="SignInButton"
                type="primary"
                // onClick={handleWhatsAppClick}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "end",
          }}
        ></div>
      </div>
    </div>
  );
};

export default TabletSecondPage;
