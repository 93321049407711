import {
  BsBookHalf,
  BsFillPeopleFill,
  BsPersonWorkspace,
} from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
// import { RiHomeOfficeFill } from "react-icons/ri";
import { SiFuturelearn } from "react-icons/si";

import webDevImg from "../../Assets/images/web-development-course.png";
import uiDesigning from "../../Assets/images/uidesigning.jpg";
import mobileapplictions from "../../Assets/images/mobileapplicationss.jpeg";
import awscloudimg from "../../Assets/images/awscloudimg.jpg";
import webApplications from "../../Assets/images/webapplications.png";
import NavbarContent from "../../components/Navbar";
import Footer from "../Footer/Footer";
import cls from "./index.module.css";
import FooterBanner from "../footerBanner";
import HeaderBanner from "../headerBanner";
import fullstackImage from "../../Assets/images/fullstackdevelopmentimage.png";
const unleashYourPotential = [
  {
    title: "Web development",
    description: "Master React, Node.js, and React Native – A Triumphant Trio",
    image: webDevImg,
  },
  {
    title: "UI Designing",
    description: "Craft Stunning Designs that Amaze",
    image: uiDesigning,
  },
  {
    title: "Web Applications",
    description: "Develop & Deploy Applications Seamlessly",
    image: webApplications,
  },
  {
    title: "AWS CloudService",
    description: "Conquer AWS Cloud Services with Confidence",
    image: awscloudimg,
  },
  {
    title: "Mobile Applications",
    description: "Dive into Mobile App Magic",
    image: mobileapplictions,
  },
];
const whyChooseUsSection = [
  {
    title: "Expert Trainers",
    description: "Learn from tech gurus who've conquered the industry.",
    image: <GiTeacher size={60} />,
  },
  {
    title: "Attractive Fee Structure",
    description: "Quality education that won't break the bank.",
    image: <GiReceiveMoney size={60} />,
  },
  {
    title: " Dynamic Atmosphere",
    description: "Learn in an environment that nurtures creativity.",
    image: <BsPersonWorkspace size={60} />,
  },
  {
    title: "Hands-On Learning",
    description: "Dive into real projects for practical mastery",
    image: <BsBookHalf size={60} />,
  },
  {
    title: "Future-Ready",
    description:
      "Our curriculum prepares you for the ever-evolving techlandscape.",
    image: <SiFuturelearn size={60} />,
  },
];
function FullStackDevelopment() {
  return (
    <>
      {/* <NavbarContent color="#eef3ff" /> */}
      <HeaderBanner
        title=" Unlocking the world of code"
        description="Experience the future of web development with our Full Stack expertise – crafting dynamic front-end experiences and powerful back-end solutions, all in one. Elevate your digital presence today!"
        image={fullstackImage}
      />
      <div className="mt-5 text-center pt-4 ">
        <div className="container h-100">
          <p
            className={`fw-bold ${cls["text-sm"]}   ${cls["text-blue-dark"]} ${cls["font-medium-bold"]}`}
          >
            <span className="fs-3">R</span>eady to become a tech wizard? Join
            our exhilarating 6-month Full Stack Development course that covers
            it all!
          </p>
          <h3 className={`mt-4 ${cls["sub-title"]}`}>
            {" "}
            Unleash Your Potential
          </h3>
          <div className={`row mx-0 ${cls["why-choose-us"]} gy-3 mt-3`}>
            {unleashYourPotential.map((item) => (
              <div className="col-md-4">
                <div className={`${cls["shadow-card"]} pb-4 h-100 rounded`}>
                  <div className={`${cls["img-container-chooseus"]}`}>
                    <img
                      src={item.image}
                      className={`${cls[`img-choose-us`]}`}
                    ></img>
                  </div>
                  <h4 className="mt-3">{item.title}</h4>
                  <p className={`px-3 ${cls[`font-small-bold`]} mb-0`}>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`${cls["bg-light"]}`}>
          <div className="container h-100 pb-5">
            <h2
              className={`mt-5 pt-5 ${cls["title-main"]} ${cls["font-extra-bold"]}`}
            >
              Why Choose Us
            </h2>
            <div className="row text-start mt-4 gy-3">
              {whyChooseUsSection.map((item) => (
                <div className="col-md-4 ">
                  <div
                    className={`h-100  py-4 px-3 rounded  ${
                      cls[`border-top-blue`]
                    }  ${cls[`bg-white`]} position-relative  ${
                      cls["cstm-shadow"]
                    }`}
                  >
                    <div className="text-center">{item.image}</div>

                    <h5
                      className={`text-center ${cls["text-blue-dark"]} fw-bold mt-3 ${cls["card-title"]}`}
                    >
                      {item.title}
                    </h5>
                    <p className={`${cls["font-small-bold"]} mb-0`}>
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <FooterBanner
          title="Launch Your Dream Career"
          subtitle="In just 6 months, transform from a novice to a Full
        Stack Pro, equipped to tackle the digital world's challenges!"
        /> */}
        {/* <Footer /> */}
      </div>
    </>
  );
}
export default FullStackDevelopment;
