import React from "react";
import "./Secondpage.scss";
import { CiCircleChevLeft } from "react-icons/ci";
import guidedimage from "../../../Assets/images/dev img-1.png";
import independent from "../../../Assets/images/dev img-2.png";
import { CiCircleChevRight } from "react-icons/ci";
import { Button, Row } from "antd";
import { mainstyle } from "../../../Confiq/MainStyle";
import { Link } from "react-router-dom";

function Secondpage() {
  return (
    <div className="secondpage-start">
      <div className="secondpage-header">
        Work Experience-Based Learning Approach
        <br />
        To Master Full Stack Developer Skills
      </div>
      <div className="second-text-build">
        Build software projects like the top 1% developers and learn all the
        skills you
        <br />
        need to land the best full stack developer jobs.
      </div>
      <div className="three-boxes">
        <div className="three-boxes-div">
          <div className="left-icon"></div>
          <div className="guided-projects-div">
            <div className="guided-projects">Guided Projects</div>
            <div className="guided-image">
              <img src={guidedimage} />
            </div>
            <div className="work-text">
              Work like Full Stack Developers
              <br />
              At Amazon.
            </div>
            <div className="build-text">
              Build a functional and scalable
              <br />
              ecommerce app by using the latest <br />
              technologies.
            </div>
          </div>
          <div className="independent-projects-div">
            <div className="independent-projects">Indipendent Project</div>
            <div className="indepentend-image">
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU"
                }
              />
            </div>
            <div className="ott-text">
              Build an OTT platform like Netflix Engineers.
            </div>
            <div className="video-text">
              Build a highly responsive,seamless
              <br />
              video streaming service Like
              <br />
              Netflix, with the latest
              <br />
              technologies.
            </div>
          </div>
          <div className="guided-projects-div">
            <div className="guided-projects">Guided Projects</div>
            <div className="guided-image">
              <img src={guidedimage} />
            </div>
            <div className="work-text">
              Work like Full Stack Developers
              <br />
              At Amazon.
            </div>
            <div className="build-text">
              Build a functional and scalable
              <br />
              ecommerce app by using the latest <br />
              technologies.
            </div>
            {/* <div className="mongo-react-ui">
              <div className="mongo">MongoDB</div>
              <div className="react">React JS</div>
              <div className="ui">UI/UX</div>
            </div>
            <div className="express-node">
              <div className="express">Express JS</div>
              <div className="node">Node JS</div>
            </div> */}
          </div>
          <div className="right-icon"></div>
        </div>
      </div>
      <Link to="/register">
        <div className="last-button-div">
          <Button
            id="button-secondpage"
            type="primary"
            style={mainstyle.SecondaryButton}
          >
            Book a FREE Trial
          </Button>
        </div>
      </Link>
    </div>
  );
}

export default Secondpage;
