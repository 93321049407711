import cls from "./index.module.css";
import { BsBookHalf, BsFillPeopleFill } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { FaLaptopCode, FaPeopleGroup } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
import Footer from "../Footer/Footer";
import NavbarContent from "../../components/Navbar";
import FooterBanner from "../footerBanner";
import childImg1 from "../../Assets/images/childrenimg1.jpg";
import childimg2 from "../../Assets/images/childrenimg2.jpg";
import childimg3 from "../../Assets/images/childrenimg3.jpg";
import childimg4 from "../../Assets/images/childrenimg4.jpg";
import childimg5 from "../../Assets/images/childrenimg5.jpg";
import childimg6 from "../../Assets/images/childrenimg6.jpg";
import creativityImage from "../../Assets/images/creativityimg.webp";
import problemSolvingimg from "../../Assets/images/problemsolving.png";
import futureProofimg from "../../Assets/images/futureproof.png";
import expressYourself from "../../Assets/images/expressyoursef.png";
import HeaderBanner from "../headerBanner";
import childimgtransparent from "../../Assets/images/childimgpng.png";

const kidsCoding = [
  {
    title: "Ignite Creativity",
    image: creativityImage,
    description: "Code your own games, stories, and digital art!",
  },
  {
    title: "Build Problem-Solving Skills",
    image: problemSolvingimg,
    description: "Tackle challenges with logic and innovation.",
  },
  {
    title: "Future-Proof Learning",
    image: futureProofimg,
    description: "Be tech-savvy and ready for the digital world.",
  },
  {
    title: "Express Yourself",
    image: expressYourself,
    description: "Turn ideas into digital wonders with your unique touch.",
  },
];
const whatWeOffer = [
  {
    image: <BsBookHalf size={60} color="white" />,
    title: "Exciting Lessons",
    description: "Engaging content that makes learning fun!",
  },
  {
    image: <GiTeacher size={60} color="white" />,
    title: "Expert Instructors",
    description: "Guided by tech-savvy mentors who love teaching.",
  },
  {
    image: <FaLaptopCode size={60} color="white" />,
    title: "Online & Offline Options",
    description: "Choose what suits your schedule best.",
  },
  {
    image: <BsFillPeopleFill size={60} color="white" />,
    title: "Interactive Activities",
    description: "Games, projects, and puzzles that spark curiosity.",
  },
  {
    image: <TbCertificate size={60} color="white" />,
    title: "Certificates",
    description: "Proudly showcase your coding achievements!",
  },
];

const discoverPath = [
  {
    title: "hours of Coding Magic",
    section: "Course A",
    number: "13",
    image: childImg1,
  },
  {
    title: "hours of Creative Coding Fun",
    section: "Course B",
    number: "13",
    image: childimg2,
  },
  {
    title: "hours of Interactive Coding Exploration",
    section: "Course C",
    number: "16",
    image: childimg3,
  },
  {
    title: "hours of Coding Wonderland",
    section: "Course D",
    number: "17",
    image: childimg4,
  },
  {
    title: "hours of Coding Adventures",
    section: "Course E",
    number: "20",
    image: childimg5,
  },
  {
    title: "hours of Digital Creativity",
    section: "Course F",
    number: "20",
    image: childimg6,
  },
];
function KidsCoding() {
  return (
    <>
      <NavbarContent />
      {/* <NavbarContent color="#eef3ff" /> */}
      <HeaderBanner
        title=" Unlocking the world of code"
        description="Nurture young minds with the magic of coding! Our kid-friendly
      coding classes inspire creativity, logic, and innovation. Prepare
      your child for a tech-savvy future while having a blast!"
        image={childimgtransparent}
      />

      <div className="text-center mt-5">
        <div className="container h-100">
          <p
            className={` text-sm mt-3 ${cls["text-blue-dark"]} fw-bold ${cls["font-italic"]}`}
          >
            <span className="fs-3">A</span>re you ready to embark on an exciting
            coding adventure? Dive into the world of technology and innovation
            with our captivating online and offline Kids Coding Classes!
          </p>
          <h3 className={`mt-5 ${cls["title-main"]} ${cls["font-extra-bold"]}`}>
            Discover Your Path
          </h3>
          {/* <h5 className="text-dark fs-4 fw-bold text-start">
            Choose from a variety of engaging <br /> sections tailored just for
            you
          </h5> */}
          <div className="row mx-0 gy-3 mt-3">
            {discoverPath.map((item, index) => (
              <div className="col-md-3" key={index}>
                <div className={`bg-light ${cls["main-card"]} border h-100`}>
                  <div className={cls["child-img-container"]}>
                    <img src={item.image}></img>
                  </div>
                  <div className="px-3 pb-3">
                    <h5 className="mt-3 text-start fw-bold fs-4 mb-0">
                      {item.section}
                    </h5>

                    <h5 className={`mt-2 text-start fs-6 `}>
                      <span
                        className={`fs-1 text-info ${cls["font-children"]} align-middle`}
                      >
                        {item.number}
                      </span>{" "}
                      {item.title}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <h2 className={`mt-5 ${cls["title-main"]} ${cls["font-extra-bold"]}`}>
            Why Kids Coding Matters{" "}
          </h2>
          <div className="row text-start mt-5">
            {kidsCoding.map((item, index) => (
              <div className="col-md-3 mt-md-0 mt-4" key={index}>
                <div
                  className={`h-100 ${cls[""]}   py-4 px-3 rounded text-center`}
                >
                  <img
                    src={item.image}
                    className={`${cls["kids-coding-img"]}`}
                  />
                  <h5 className={`text-center mt-3 ${cls["text-blue-red"]}  `}>
                    {item.title}
                  </h5>
                  <p className={`${cls["font-small-bold"]} mb-0 text-center`}>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={`${cls["bg-light"]} pb-4`}>
          <div className="container h-100">
            <h2
              className={`mt-5 pt-4 ${cls["title-main"]} ${cls["font-extra-bold"]}`}
            >
              What We Offer
            </h2>
            <div className="row text-start mt-4 gy-3">
              {whatWeOffer.map((item, index) => (
                <div className="col-md-4 " key={index}>
                  <div
                    className={`h-100  pt-4 ${
                      cls["cstm-shadow"]
                    } d-flex align-items-end ${
                      cls["border-top-blue"]
                    } position-relative ${
                      index === 0
                        ? cls["bg-img1"]
                        : index === 1
                        ? cls["bg-img2"]
                        : index === 2
                        ? cls["bg-img3"]
                        : index === 3
                        ? cls["bg-img4"]
                        : cls["bg-img5"]
                    }`}
                  >
                    {/* <div className="text-center">{item.image}</div> */}
                    <div className={`${cls["cstm-img-shadow"]} px-3 `}>
                      <h5
                        className={`text-start text-light fw-bold mt-3 ${cls["z-index"]} ${cls["card-title"]}`}
                      >
                        {item.title}
                      </h5>
                      <p
                        className={`font-small-bold mb-0 text-start ${cls["text-warning"]}`}
                      >
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FooterBanner
          title="Unleash Your Potential"
          subtitle="Don't just use technology – create it! Enroll
        today and unlock the power of coding in a fun and interactive
        way."
        />
        <Footer />
      </div>
    </>
  );
}
export default KidsCoding;
