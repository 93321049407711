import googleads from "../../Assets/images/googleadsimg.png";
import seosecrets from "../../Assets/images/seosecrets.png";
import socialmediicons from "../../Assets/images/socialmediaimgs.png";
// import {BiLogoGmail} from 'react-icons/bi';
import cls from "./index.module.css";
import NavbarContent from "../../components/Navbar";
import digitalMarketingimg from "../../Assets/images/digitialmarketingimg.png";
import HeaderBanner from "../headerBanner";
import Footer from "../Footer/Footer";

const exploreDigital = [
  {
    image: socialmediicons,
    title: "Social Media Mastery ",
  },
  {
    image: seosecrets,
    title: "SEO Secrets Unveiled",
  },
  {
    image: googleads,
    title: "Google Ads Domination",
  },
];

const whyChooseUs = [
  {
    title: "Expert Tutors",
    description:
      "Learn from industry professionals who have conquered the digital realm.",
  },
  {
    title: "Placement Assurance",
    description:
      "Your success is our priority – we guarantee placement assistance.",
  },
  {
    title: " Hands-On Learning",
    description:
      " Dive into real campaigns and projects, gaining practical skills.",
  },
];

function DigitalMarketing() {


  return (
    <>
      <NavbarContent/>
      <HeaderBanner
        title="Unlocking the world of code"
        description="Boost your online presence with our digital marketing pros. From SEO to captivating social media campaigns, we'll help you excel.
         Maximize conversions, elevate your brand, and outshine the competition. Join us to explore the future of marketing!"
        image={digitalMarketingimg}
      />
      <div className="text-center pt-5">
        <div className="container mb-5 h-100">
          <p
            className={`fw-bold text-sm mt-3 ${cls["text-blue-dark"]} ${cls["font-medium-bold"]}`}
          >
            " <span className="fs-3">A</span>re you ready to soar in the world
            of digital marketing? Our comprehensive 6-month course is your
            ticket to success! "
          </p>
          <h3 className={`mt-4 ${cls["sub-title"]}`}>
            {" "}
            Explore the Digital Landscape{" "}
          </h3>
          <div className="row mt-5">
            {exploreDigital.map((item) => (
              <div className="col-md-4 ">
                <div className="d-flex justify-content-center">
                  <div
                    className={`text-center ${cls["explore-img-container"]}`}
                  >
                    <img src={item.image} className="img-fluid"></img>
                    <div></div>
                  </div>
                </div>
                <h4 className={`mt-2 ${cls["card-title"]} ${cls["cstm"]}`}>
                  {item.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
        <div className={`${cls["bg-light"]} pb-5`}>
          <h2
            className={`mt-3 pt-5 ${cls["title-main"]} ${cls["font-extra-bold"]}`}
          >
            Why Choose Us?{" "}
          </h2>
          <div className="container h-100 px-0">
            <div className="row text-start mt-4 mx-0">
              {whyChooseUs.map((item) => (
                <div className="col-md-4 mt-md-0 mt-4">
                  <div
                    className={`h-100 ${cls["bg-light-blue"]}  py-4 px-3 rounded shadow-sm`}
                  >
                    <h4
                      className={`text-center fw-bold  ${cls["card-title"]}  ${cls["text-blue-dark"]}`}
                    >
                      {item.title}
                    </h4>
                    <p className={`${cls["font-small-bold"]} mb-0`}>
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <FooterBanner
          title={` Don't just learn – thrive! `}
          subtitle="Enroll today and launch your
              digital marketing journey with confidence."
        /> */}
        <Footer />
      </div>
    </>
  );
}
export default DigitalMarketing;
// import React from 'react'

// function index() {
//   return (
//     <div>index</div>
//   )
// }

// export default index