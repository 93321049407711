import React, { useState, useRef } from "react";
import HDNavbarContent from './HDnavbar'
import './HDQ&Asession.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from 'antd';

function HDQandAsession() {

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    infinite: false,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleClick = () => {
    sliderRef.current.slickNext("");
    setCurrentIndex(currentIndex + 1)
  };

  const div1 = {
    backgroundColor: currentIndex >= 0 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const div2 = {
    backgroundColor: currentIndex >= 1 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const div3 = {
    backgroundColor: currentIndex >= 2 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const div4 = {
    backgroundColor: currentIndex >= 3 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const div5 = {
    backgroundColor: currentIndex >= 4 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const div6 = {
    backgroundColor: currentIndex >= 5 ? '#6e2fff' : 'rgb(92, 83, 83)' , 
  };
  const line1 = {
    backgroundColor: currentIndex >= 1 ? '#6e2fff' : '#abbff2' , 
  };
  const line2 = {
    backgroundColor: currentIndex >= 2 ? '#6e2fff' : '#abbff2' , 
  };
  const line3 = {
    backgroundColor: currentIndex >= 3 ? '#6e2fff' : '#abbff2' , 
  };
  const line4 = {
    backgroundColor: currentIndex >= 4 ? '#6e2fff' : '#abbff2' , 
  };
  const line5 = {
    backgroundColor: currentIndex >= 5 ? '#6e2fff' : '#abbff2' , 
  };

  return (
    <div className='fullpage-HDQandAsession'>
      <HDNavbarContent />
      <div className='HDQandAsession-header' >
        <div className="InputMainDiv">
          <Slider ref={sliderRef} {...settings}>
            <div className="inputDiv">
              Your full name
              <input className="InputHDQandAsession" placeholder="Student full name" required/>
            </div>
            <div className="inputDiv">
              Place
              <input className="InputHDQandAsession" placeholder="Type your place" />
            </div>
            <div className="inputDiv">
              Country
              <input className="InputHDQandAsession" placeholder="Choose your Country"/>
            </div>
            <div className="inputDiv">
              Phone number
              <input className="InputHDQandAsession" placeholder="Enter phone number" />
            </div>
            <div className="inputDiv">
              Mail
              <input className="InputHDQandAsession" placeholder="Enter mail id" />
            </div>
            <div className="inputDiv">
              Education
              <input className="InputHDQandAsession" placeholder="Your education" />
            </div>
          </Slider>
        </div>
        <p style={{ height: '1px', color: 'transparent' }}>{currentIndex + 1}</p>
        <div className="HDQandAsessionButtonDiv">
          <Button className="HDQandAsessionButton" type="primary" onClick={handleClick}>Next {'>'}</Button>
        </div>
        <div className="HDQandAsessionStandCount" >
        <div style={{display:'flex',width:'100%',alignItems:'center'}}>
          <div className="number" style={div1}>01</div>
          <div className="line" style={line1}/>
          <div className="number"  style={div2}>02</div>
          <div className="line" style={line2}/>
          <div className="number"  style={div3}>03</div>
          <div className="line" style={line3}/>
          <div className="number"  style={div4}>04</div>
          <div className="line" style={line4}/>
          <div className="number"  style={div5}>05</div>
          <div className="line" style={line5}/>
          <div className="number"  style={div6}>06</div>
        </div>
        </div>
      </div>
      <hr className="strigtLine" />
      <div className='copyRight'> Copyright ©  www.semikolen.com 2023</div>
    </div>
  )
}

export default HDQandAsession