import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Input, Button, Form } from "antd";
import PhoneInput from "react-phone-number-input";

const RegisterMobileSecondStep = () => {
  //   const navigation = useNavigate();
  const [fullName, setFullName] = useState(""); // Add state for each form field
  const [studentName, setStudentName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [residentialAddress, setResidentialAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");

  //   const click = () => {
  //     navigation("/RegisterMobileSecondStep", {});
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <div>
      <div className="headerSignIn">
        <h2>Join with us...</h2>
        <h6>
          Submit your queries here and we <br />
          will get to you as soon as
          <br />
          possible
        </h6>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          className="servicesDivSignIn d-flex d-sm-none "
          style={{ marginTop: "100px" }}
          onSubmit={handleSubmit}
        >
          <Form.Item label="Higher Education">
            <Input
              required
              placeholder="Type your Higher Education here."
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Relevent Education">
            <Input
              required
              placeholder="Type your Relevent Education here."
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Language">
            <Input
              placeholder="Type your Language"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Select Your education">
            <Input
              placeholder="Type your Select Your education"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Online or ofline">
            <Input
              placeholder="Type your Online or ofline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Selection Of Course">
            <Input
              placeholder="Enter Your Selection Of Course"
              value={residentialAddress}
              onChange={(e) => setResidentialAddress(e.target.value)}
            />
          </Form.Item>
          {/* <Form.Item label="Mobile Number">
            <PhoneInput
              placeholder="XXXX XXX XXX"
              defaultCountry="IN"
              value={mobileNumber}
              onChange={setMobileNumber}
            />
          </Form.Item> */}
          <Form.Item label="Preferable Duration">
            <Input
              placeholder="Enter Your Preferable Duration"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Start Date">
            <Input
              placeholder="Enter Your Start Date"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Form.Item>
          <div style={{ width: "90%", display: "flex", justifyContent: "end" }}>
            <div style={{ height: "10px" }}>
              <Form.Item>
                <Button
                  className="NextButton"
                  type="primary"
                  htmlType="submit"
                  style={{ height: "30px" }}
                >
                  Submit
                </Button>{" "}
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterMobileSecondStep;
