import React from 'react'
import './HDinsidePage.css'
import { Button } from 'antd';
import HDNavbarContent from './HDnavbar';
import { Link } from 'react-router-dom';
import NavbarContent from '../components/Navbar';


function HDinsidePage() {
  return (
    <>
    <div className='HDinsidePageContainer d-block d-lg-none'>
      <HDNavbarContent />
      <div className='HDinsidePageContainerMain  d-flex d-sm-none'>
        <div className='HDinsidePageHeading'>Lets begin...</div>
        <div className='HDinsidePageText'>Thank you for your unterest in <b>SEMIKOLEN</b> to fulfill your hiring needs. We would like to know more<br /> about your requirements.<br />So lets answer the onscreen Q&A Session.</div>
        <Link className='HDinsidePageButton' to='/Q&Asession'>
          <Button style={{ fontWeight: 500 }} type="primary">Start</Button>
        </Link>
        <div className='HDinsidePageUnderText'>to start click or press Enter key<div style={{ color: 'black', fontSize: '15px' }}>&#8626;</div></div>
      </div>
      {/* Tab */}
      <div className='HDinsidePageContainerMain d-none d-sm-flex d-lg-none'>
        <div className='HDinsidePageHeading '>Lets begin...</div>
        <div className='HDinsidePageTextTab'>Thank you for your unterest in <b>SEMIKOLEN</b> to fulfill your hiring needs. We would like to know more<br /> about your requirements.<br />So lets answer the onscreen Q&A Session.</div>
        <Link className='HDinsidePageButtonTab' to='/Q&Asession'>
          <Button style={{ fontWeight: 500 }} type="primary">Start</Button>
        </Link>
        <div className='HDinsidePageUnderText'>to start click or press Enter key<div style={{ color: 'black', fontSize: '15px' }}>&#8626;</div></div>
      </div>
    </div>
    {/* Lap */}
    <div className='HDinsidePageContainerLap d-none d-lg-flex'>
      <HDNavbarContent />
      <div className='HDinsidePageContainerMainLap  d-none d-lg-flex'>
        <div className='HDinsidePageLapContentDiv'>
        <div className='HDinsidePageHeading'>Lets begin...</div>
        <div className='HDinsidePageTextLap'>Thank you for your unterest in <b>SEMIKOLEN</b> to fulfill<br /> your hiring needs. We would like to know more<br /> about your requirements.<br />So lets answer the onscreen Q&A Session.</div>
        <Link className='HDinsidePageButtonLap' to='/Q&Asession'>
          <Button style={{ fontWeight: 500 }} type="primary">Start</Button>
        </Link>
        <div className='HDinsidePageUnderText'>to start click or press Enter key<div style={{ color: 'black', fontSize: '15px' }}>&#8626;</div></div>
      </div>
      </div>
      <hr className="strigtLine" />
      <div className='copyRight'> Copyright ©  www.semikolen.com 2023</div>
      </div>
    </>
  )
}

export default HDinsidePage