import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: "AKIAYCY7LW3IVD3BX3WJ",
  secretAccessKey: "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",
  region: "eu-west-2",
});

export const UploadToS3 = (file, filename) => {
  const params = {
    Bucket: "semikolen-bucket",
    Key: filename,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};

export const UploadToImageS3 = (file2, fileName2) => {
  const params = {
    Bucket: "semikolen-bucket",
    Key: fileName2,
    Body: file2,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};

// const uploadToS3 = async(file);
