import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Form, Input, Select, Upload } from "antd";
import moment from "moment";
const RegisterStep3 = (props) => {
  const { formData } = props;

  const handleWhatsAppClick = (values) => {
    console.log(values, "===========================values");
    const messageObj = {
      FullName: values?.full_name,
      qualification: values?.qualification,
      City: values?.city,
      Email: values?.email,
      Phone: values?.mobile,
    };

    const messageString = Object.entries(messageObj)
      .map(([key, value]) => `*${key}*: ${value}`)
      .join("\n");

    const subject = "Contact Form Submission";

    const encodedMessageString = encodeURIComponent(
      `${subject}\n\n${messageString}`
    );

    const phone = "+919074702722";
    const baseUrl = "https://api.whatsapp.com/send/";
    const url = `${baseUrl}?phone=${phone}&text=${encodedMessageString}`;

    window.open(url);
  };

  return (
    <div className="">
      <div className="RegistrationDetails-Main">
        <br />
        <br />
        <div
          style={{ display: "flex", justifyContent: "center", width: "91%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2>Registration Details</h2>
          </div>
        </div>

        <div className="RegistrationDetailsImageUploader-Mobile">
          <img
            src={formData.imageUrl2}
            style={{ width: "150px", height: "150px", borderRadius: "3px" }}
          />
        </div>
        <br />

        <div className="RegistrationDetailsall-Details">
          <div>
            <p>
              <b>Full Name: </b>
              {formData.full_name}
            </p>
            <p>
              <b>Date of Birth: </b>
              {moment(formData.dob.toString()).format("MMM Do YY")}
            </p>
            <p>
              <b>Nationality:</b> {formData.nationality}
            </p>
            <p>
              <b>Gender:</b> {formData.gender}
            </p>
          </div>
          <div className="RegistrationDetailsImageUploader">
            <img
              src={formData.imageUrl2}
              style={{ width: "150px", height: "150px", borderRadius: "3px" }}
            />
          </div>
        </div>
        <div className="RegistrationDetailsdetailsdata">
          <p>
            <b>email:</b> {formData.email}
          </p>
          <p>
            <b>address1:</b> {formData.address1}
          </p>
          <p>
            <b>address2: </b>
            {formData.address2}
          </p>
          <p>
            <b>city: </b>
            {formData.city}
          </p>
          <p>
            <b>district: </b>
            {formData.district}
          </p>
          <p>
            <b>Phone Number: </b>
            {formData.mobile}
          </p>
          <p>
            <b>Postal Code:</b> {formData.pin}
          </p>
          <p>
            <b>state: </b>
            {formData.state}
          </p>
          <p>
            <b>language:</b> {formData.language}
          </p>
          <p>
            <b>qualification: </b>
            {formData.qualification}
          </p>
          <p>
            <b>relevent_education: </b>
            {formData.relevent_education}
          </p>
          <p>
            <b>Education: </b>
            {formData.education}
          </p>
          <p>
            <b>selection_of_course: </b>
            {formData.selection_of_course}
          </p>
          <p>
            <b>specialisation: </b>
            {formData.specialisation}
          </p>
          <p>
            <b>Year Of Completrion: </b>
            {formData.year_of_comp}
          </p>
        </div>
        <br />
        <Row>
          <Col md="3"></Col>
          <Col md="3">
            <div
              onClick={() => props.goBack()}
              className="register-back-button"
            >
              Back
            </div>
          </Col>
          <Col md="3">
            <Form.Item>
              <button
                className="register-next-button"
                onClick={() => {
                  props.submitData();
                  handleWhatsAppClick(formData);
                  window.history.back();
                }}
              >
                Submit
              </button>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <br />
    </div>
  );
};

export default RegisterStep3;
