import React from 'react'
import SemikolenLogo from '../Assets/Semikolenlogoonly.svg'
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import './SignUp.css'


function SignUp() {

  return (

    // mobile view 
    <>
      <div className="App d-block d-lg-none">
        <main>
          <div className='header'>
            <h2>Sign Up</h2>
            <h6>sign up to join with us and < br />have a bright career...</h6>
          </div>
          <section className="serviceSignUps">
            <div className='servicesDiv d-sm-none'>
              <div className='continueDiv'>
                <Input placeholder="enter your username or mail id" />
              </div>
              <div className='continueDiv'>
                <Input.Password placeholder="enter your 8 digit passsword here" visibilityToggle={{}} />
              </div>
              <div className='continueDiv'>
                <Button className='BookYourFreeTrialButton' type="primary" block>Submit</Button>
              </div>
              <Link className='UnderTextSignUp' to='/LogIn' >Alredy have an account? <b> Sign Up</b></Link>
            </div>

            {/* Tab */}
            <div className='servicesDiv d-none d-sm-block d-lg-none w-50'>
              <div className='servicesDivMain'>
                <div className='continueDivSignUpTab'>
                  <Input placeholder="enter your username or mail id" />
                </div>
                <div className='continueDivSignUpTab'>
                  <Input.Password placeholder="enter your 8 digit passsword here" visibilityToggle={{}} />
                </div>
                <div className='continueDiv'>
                  <Button className='BookYourFreeTrialButton' type="primary" block>Submit</Button>
                </div>
                <Link className='UnderTextSignUp' to='/LogIn' >Alredy have an account? <b> Sign Up</b></Link>
              </div>
            </div>
          </section>
        </main>
      </div>

      {/* Lap */}
      <div className="AppLap d-none d-lg-block w-100">
        <main className='ContainerLap d-lg-flex h-100 w-100'>
          <div className='headerLap'>
            <div className='headerLapDiv'>
              <img className='headerLapDivImage' src={SemikolenLogo} alt='' />
              <h4>Sign in</h4>
              <h6>sign in to join with us and < br />have a bright career...</h6>
            </div>
          </div>
          <section className="servicesLap" style={{ backgroundColor: 'white' }}>
            <div className='servicesDivLapMain'>
              <div className='continueDiv'>
                <Input placeholder="enter your username or mail id" />
              </div>
              <div className='continueDiv'>
                <Input.Password placeholder="enter your 8 digit passsword here" visibilityToggle={{}} />
              </div>
              <div className='continueDiv'>
                <Button className='BookYourFreeTrialButton' type="primary" block>Submit</Button>
              </div>
              <Link className='UnderTextSignUp' to='/LogIn' >Alredy have an account? <b> Sign Up</b></Link>
            </div>
          </section>
        </main>
      </div>


    </>
  )
}

export default SignUp