import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { mainstyle } from "../Confiq/MainStyle";
import { Button } from "antd";
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import SemikolenLogoOnly from "../Assets/footerlogosemikolen.svg";
import { Link } from "react-router-dom";
import "./HDnavbar.css";
import NavBarModel from "../home/components/hero/NavBarModel";
import { Navbar } from "react-bootstrap";

function HDNavbarContent() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Container fluid>
          <div className="toggler-div" onClick={showModal}>
            <IoMenu color="white" size={25} />
          </div>
          <Navbar.Brand href="#home">
            <div className="logo2HD">
              <img src={SemikolenLogoOnly} style={{ width: "100%" }} />
            </div>
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <div className="selection-div">
                <a className="home-div activeHD" href="#home">
                  Home
                </a>
                <a className="programs-div activeHD" href="#courses">
                  courses
                </a>
                <Link to="/HDfirstpage" className="text-decoration-none">
                  <a className="hire-div activeHD">Hire a Developer</a>
                </Link>
                <a className="blog-div activeHD" href="#blog">
                  Blog
                </a>
                <a className="about-div activeHD" href="#about">
                  About
                </a>
              </div>

              <div className="button-div">
                <Link to="/SignUp" className="text-decoration-none">
                  <button className="button1HD">
                    <a href="#">
                      <div className="signHD">Sign In</div>
                    </a>
                  </button>
                </Link>
                <Link to="/register">
                  <Button type="primary" style={mainstyle.SecondaryButton}>
                    Book a FREE Trial
                  </Button>
                </Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isModalOpen ? (
        <NavBarModel visible={isModalOpen} onCancel={handleCancel} />
      ) : null}
    </>
  );
}

export default HDNavbarContent;
