import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Form, Input, Select, Upload, message } from "antd";
import "./styles.css";
import { BiArrowBack } from "react-icons/bi";
import { BsUpload } from "react-icons/bs";
import { UploadToImageS3, UploadToS3 } from "../Config/s3fileUpload";
import API from "../Config/api";
import axios from "axios";
import moment from "moment";

const RegisterStep2 = (props) => {
  const { Option } = Select;
  const [ImageUrl, setImageUrl] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");

  const onNextClick = (values) => {
    props.onSubmit(values, ImageUrl, imageUrl2);
    props.next();
  };

  // const loadData = () => {
  //   let url = API.BASE_URL + API.REGISTER;
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       let imageData = response.data.data;
  //       setData(imageData);
  //       // setIsloading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const onUpload = async (val) => {
    let file = val.file.originFileObj;
    console.log(" pickerName------------>>>>>>>>>>>>", file);

    const fileName = `${moment().format("YYYY-MM-DDHH:MM:SS")}semikolen.png`;
    const response = await UploadToS3(file, fileName);
    console.log(response, "_______file______>>>>>");
    setImageUrl(response.Location);
  };

  const onUpload2 = async (val) => {
    let file2 = val.file.originFileObj;

    const fileName2 = `${moment().format("YYYY-MM-DDHH:MM:SS")}semikolen.png`;
    const response = await UploadToImageS3(file2, fileName2);
    console.log(response, "_______file222222222222222222______>>>>>");

    setImageUrl2(response.Location);
  };

  return (
    <div className="">
      <Form onFinish={onNextClick}>
        <div className="RegisterMain-Div">
          <div className="register-header">Other Info</div>
          <br />
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Education</div>
                <Form.Item name="education" rules={[{ required: true }]}>
                  <Select bordered={false}>
                    <Option key={1} value={"Post Graduation Pg"}>
                      Post Graduation
                    </Option>
                    <Option key={2} value={"Graduation Ug"}>
                      Graduation
                    </Option>
                    <Option key={3} value={"Poly"}>
                      Poly
                    </Option>
                    <Option key={4} value={"Iti"}>
                      ITI
                    </Option>
                    <Option key={5} value={"Higher Secondary"}>
                      Higher Secondary
                    </Option>
                    <Option key={6} value={"sslc"}>
                      SSLC
                    </Option>
                    <Option key={7} value={"other"}>
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Specialisation</div>
                <Form.Item name="specialisation" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Year of Completion</div>
                <Form.Item name="year_of_comp" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <Upload
                  onChange={(val) => {
                    onUpload2(val, "");
                    console.log(val.file.originFileObj);
                  }}
                >
                  <Form.Item
                    name="imageurl2"
                    // rules={[{ required: true }]}
                    style={{ height: "0px" }}
                  >
                    <div className="file-upload">
                      Click to Upload Photo
                      <BsUpload />
                    </div>
                  </Form.Item>
                </Upload>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Relevent Education</div>
                <Form.Item
                  name="relevent_education"
                  rules={[{ required: true }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <Upload
                  onChange={(val) => {
                    onUpload(val, "year_of_comp");
                    console.log(val.file.originFileObj);
                  }}
                >
                  <Form.Item
                    name="imageurl1"
                    // rules={[{ required: true }]}
                    style={{ height: "0px" }}
                  >
                    <div className="file-upload">
                      Click to Upload Id (ex=Aadhar)
                      <BsUpload />
                    </div>
                  </Form.Item>
                </Upload>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Qualification</div>
                <Form.Item name="qualification" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Language</div>
                <Form.Item name="language" rules={[{ required: true }]}>
                  <Select bordered={false}>
                    <Option key={1} value={"english"}>
                      English
                    </Option>
                    <Option key={2} value={"malayalam"}>
                      Malayalam
                    </Option>
                    <Option key={3} value={"hindi"}>
                      Hindi
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Online Or Offline</div>
                <Form.Item
                  name="online_or_offline"
                  rules={[{ required: true }]}
                >
                  <Select bordered={false}>
                    <Option key={1} value={"online"}>
                      Online
                    </Option>
                    <Option key={2} value={"offline"}>
                      Offline
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Selection Of Course</div>
                <Form.Item
                  name="selection_of_course"
                  rules={[{ required: true }]}
                >
                  <Select bordered={false}>
                    <Option key={1} value={"Full Stack Development"}>
                      Full Stack Development
                    </Option>
                    <Option key={2} value={"Web Designing"}>
                      Web Designing
                    </Option>
                    <Option
                      key={2}
                      value={"Mobile App Development in React Nature"}
                    >
                      Mobile & Development in React Nature
                    </Option>
                    <Option key={2} value={"Backend Development"}>
                      Backend Development
                    </Option>
                    <Option key={2} value={"Software Testing & Automation"}>
                      Software Testing & Automation
                    </Option>
                    <Option key={2} value={"Prompt Engineering With Ai Tools"}>
                      Prompt Engineering With Ai Tools
                    </Option>
                    <Option key={2} value={"Digital Marketing"}>
                      Digital Marketing
                    </Option>
                    <Option key={2} value={"Kids Coding"}>
                      Kids Coding
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Preferable Duration</div>
                <Form.Item
                  name="preferable_duration"
                  rules={[{ required: true }]}
                >
                  <Select bordered={false}>
                    <Option key={1} value={"1 year"}>
                      1 year
                    </Option>
                    <Option key={2} value={"6 months"}>
                      6 months
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Comments</div>
                <Form.Item name="Comments" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <br />
          <Row>
            <Col md="3" className="abcd"></Col>
            <Col md="3">
              <div
                onClick={() => props.goBack()}
                className="register-back-button"
              >
                Back
              </div>
            </Col>
            <br />
            <br />
            <br />
            <Col md="3">
              <Form.Item>
                <button
                  className="register-next-button"
                  onClick={() => console.log("")}
                >
                  Sumbitt
                </button>
              </Form.Item>
            </Col>
            <Col md="3" className="abcd"></Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default RegisterStep2;
