import React from 'react';
import './ShareButton.css';
import {BiShareAlt} from 'react-icons/bi'

const ShareButton = () => {
  const handleShare = () => {
    // Add your share functionality here
    console.log('Share button clicked!');
  }

  return (
    <h className="share-button" onClick={handleShare}>
      <i className="fas fa-share-alt"><BiShareAlt/></i>
    </h>
  );
}

export default ShareButton;
