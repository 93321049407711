import "./App.less";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/reset.css";
import Header from "./home/components/hero";
import Firstpage from "./home/components/Firstpage/Firstpage";
import Secondpage from "./home/components/Second page/Secondpage";
import Thirdpage from "./home/components/Third Page/Thirdpage";
// import Fourthpage from "./FourthPage/Fourthpage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CoursesTrial from "./home/components/coursesTrial/coursesTrial";
import Footer from "./components/Footer/Footer";
// import ExporeCoress from "./ExporeCoress/ExporeCoress";
import LogIn from "./LogIn/LogIn";
// import SignIn from "./LogIn/SignInPage";
import SignUp from "./LogIn/SignUp";
import BookYourFreeTrial from "./BookYourFreeTrialLogin/FreeTrial";
import HDfirstpage from "./Hire a developer/HDfirstpage";
import HDinsidePage from "./Hire a developer/HDinsidePage";
import HDQandAsession from "./Hire a developer/HDQ&Asession";
import Blogmain from "./Blog/Blogmain";
import BlogSeconPage from "./BlogSeconPage/BlogSeconPage";
import Splash from "./SplashScreen/Splash";
import BlogCreatePage from "./BlogCreatePage/BlogCreatePage";
import TermsAndConditions from "./BookYourFreeTrialLogin/TermsConditions";
import InformationFirstStep from "./BookYourFreeTrialLogin/AdditionalInformationFirstStep";
import DashbordMain from "./AdminPage/components/header";
import RegistrationDetails from "./AdminPage/components/datas";
import RegisterMobileSecondStep from "./BookYourFreeTrialLogin/mobileSecondPage";
import TabletSecondPage from "./BookYourFreeTrialLogin/TabletSecondPage";
import Register from "./Register";
import RegisterStep2 from "./Register/registerStep2";
import ContactScreen from '../src/contactScreen'
// import "bootstrap/dist/css/bootstrap.min.css";
// import "antd/dist/reset.css";
// import Firstpage from "./Firstpage/Firstpage";
// import Secondpage from "./Second page/Secondpage";
// import Thirdpage from "./Third Page/Thirdpage";
// import Fourthpage from "./Fourth Page/Fourthpage";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import CoursesTrial from "./coursesTrial/coursesTrial";
// import Footer from "./Footer/Footer";
// import ExporeCoress from "./ExporeCoress/ExporeCoress";
// import LogIn from "./LogIn/LogIn";
// import SignIn from "./LogIn/SignInPage";
// import SignUp from "./LogIn/SignUp";
// import BookYourFreeTrial from "./BookYourFreeTrialLogin/FreeTrial";
// import HDfirstpage from "./Hire a developer/HDfirstpage";
// import HDinsidePage from "./Hire a developer/HDinsidePage";
// import HDQandAsession from "./Hire a developer/HDQ&Asession";
// import Blogmain from "./Blog/Blogmain";
// import BlogSeconPage from "./BlogSeconPage/BlogSeconPage";
// import Splash from "./SplashScreen/Splash";
// import BlogCreatePage from "./BlogCreatePage/BlogCreatePage";
import DigitalMarketing from "./components/digitalMarketing";
import KidsCoding from "./components/kidsCoding";
import FullStackDevelopment from "./components/fullStackDevelopment";
import ScrollToTop from "./components/scrollTop";
import CoursesPage from "./components/coursesPage";
import Home from "./home";
import Testimonials from "./home/components/testimonials";
// import WebDesigning from "./components/webDesigning";
function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            {/* <Route
              exact
              path="/"
              element={
                <div>
                  <Splash />
                  <Firstpage />
                  <Secondpage />
                  <Thirdpage />
                  <Fourthpage />
                  <CoursesTrial />
                  <Footer />
                </div>
              }
              /> */}
              {/* <WebDesigning/> */}
              {/* <DigitalMarketing /> */}
              {/* <FullStackDevelopment /> */}
              {/* <KidsCoding /> */}
            <Route path="/" element={<Home />} />
            <Route path="/BookYourFreeTrial" element={<BookYourFreeTrial />} />
            <Route path="/contact" element={<ContactScreen />} />
            {/* <Route path="/BookYourFreeTria" element={<ExporeCoress />} /> */}
            {/* <Route path="/SignIn" element={<SignIn />} /> */}
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/LogIn" element={<LogIn />} />
            <Route path="/hireAdeveloper" element={<HDfirstpage />} />
            <Route path="/Blognavbar" element={<Blogmain />} />
            <Route path="/HDinsidePage" element={<HDinsidePage />} />
            <Route path="/Q&Asession" element={<HDQandAsession />} />
            <Route path="/BlogSeconPage" element={<BlogSeconPage />} />
            <Route path="/createblog" element={<BlogCreatePage />} />
            {/* <Route path="/BlogCreate" element={<BlogCreatePage />} /> */}
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/TabletSecondPage" element={<TabletSecondPage />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/RegisterMobileSecondStep"
              element={<RegisterMobileSecondStep />}
            />
            <Route
              path="/RegistrationDetails"
              element={<RegistrationDetails />}
            />
            <Route
              path="/InformationFirstStep"
              element={<InformationFirstStep />}
            />
            <Route path="/AdminPage" element={<DashbordMain />} />
            <Route path="/RegisterStep2" element={<RegisterStep2 />} />
            <Route path="/BlogCreate" element={<BlogCreatePage />} />
            <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
            <Route path="/KidsCoding" element={<KidsCoding />} />
            <Route path="/FullStackDev" element={<FullStackDevelopment />} />
            <Route path="/CoursesPage" element={<CoursesPage />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
