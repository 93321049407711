import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Checkbox } from "antd";
import "./styles.css";
import { Row, Col } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import { TbArrowBigRightLineFilled } from "react-icons/tb";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { MdArrowRight } from "react-icons/md";

const TermsAndConditions = (props) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleContinueClick = () => {
    if (isAgreed) {
      props.next();
    } else {
      alert("Please agree to the Terms & Conditions.");
    }
  };

  return (
    <>
      <div className="TermsAndConditionsMain-Div">
        <div className="imgsrc">
          <div className="imgsrcdiv">Terms & Conditions</div>
        </div>
      </div>
      <div className="termsandconditionsmaintexth1-Div">
        <h1 className="h1">
          In Using Website You Are deemed to have read and <br /> agreed to the
          following terms and conditions{" "}
        </h1>
      </div>
      <br />
      <div className="termsandconditionsmainptextmain-Div">
        <div style={{ width: "80%" }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Submission of this application does not guarantee enrolment.
              Admission is subject to availability, course prerequisites, and
              the fulfilment of other institute requirements.
            </span>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              The institute reserves the right to modify course content,
              schedule, faculty, or any other aspect without prior notice.
              Efforts will be made to inform enrolled students of any changes.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Regular attendance is essential for successful completion of the
              course. The institute may set attendance requirements, and failure
              to meet them may affect your progress.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Students are expected to maintain a respectful and professional
              attitude towards peers, faculty, and staff. Any behaviour deemed
              disruptive or disrespectful may lead to disciplinary action.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              By submitting this form, you consent to the collection, storage,
              and processing of your personal information by the institute for
              administrative and educational purposes.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Earning a certificate is subject to successful completion of all
              course requirements, as determined by the institute's evaluation
              criteria.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              The institute is not liable for any loss, damage, or injury
              sustained during the course of your participation in its programs.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              The primary mode of communication will be through the contact
              details provided in this form. Ensure your email and phone
              information is accurate and up to date.{" "}
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Please be advised that these terms and conditions are subject to
              and will be construed in accordance with the laws and regulations
              of the jurisdiction in which our institute operates.{" "}
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              payment Upon acceptance, the course fee is due as per the
              institute's payment terms. Non-payment may result in the
              cancellation of your enrolment.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              Refund policies are outlined in the institute's refund policy
              document. Requests for cancellations and refunds must be submitted
              in writing within the stipulated timeframe.
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              50 percentage within 15 days
            </span>
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <TbArrowBigRightLineFilled className="termsandcondition-Arrow" />
            <span style={{ verticalAlign: "baseline" }}>
              35 percentage 16-30 days{" "}
            </span>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <b>
          <Checkbox
            style={{ fontSize: "15px" }}
            onChange={handleCheckboxChange}
          >
            I agree to the Terms & Condition
          </Checkbox>
        </b>
      </div>
      <br />

      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <div onClick={handleContinueClick} className="register-next-button">
            Continue
          </div>
        </Col>
        <Col md="4"></Col>
      </Row>
      <br />
      <br />
    </>
  );
};

export default TermsAndConditions;
