import React, { useEffect, useState } from 'react'
import './BlogSeconPageSecond.css'
import axios from 'axios';

function BlogSeconPageSecond() {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.get('https://suprabhaatham-dev.herokuapp.com/api/semikolens')
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);
    console.log(data)
    return (
        <>

            <div className='secondBlogSecondStart d-none d-lg-flex'>
                <div className='secondAreaAddDiv'>
                    <div className='secondAreaAdd'>
                        Area For Ad
                    </div>
                </div>
                <div className='secondBlogSecondBoxesDiv' >
                    <div className='secondBlogSecondBlogBoxesMainDiv'>
                        {data.slice(0, 4).map(item => (

                            <div className='secondBlogSecondBlogBoxes'>
                                <div className='pictureDiv' key={item.id}>
                                    <img className='secondBlogSecondImage' src={item.attributes.image} />
                                </div>
                                <div className='secondBlogSecondBoxHeader'>
                                    {item.attributes.tittle}
                                </div>
                                <div className='secondBlogSecondBoxHeaderText'>
                                    {item.attributes.category}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='BlogUnderLine'>
                    <div style={{ backgroundColor: '#d7d7d7', width: '76%', height: '1.4px' }}></div>
                </div>
            </div>

            {/* Mobile */}
            <div className='secondBlogSecondStartMob d-flex d-sm-none'>
                <div className='secondAreaAddDivMob'>
                    <div className='secondAreaAddMob'>
                        Area For Ad
                    </div>
                </div>
                <div className='secondBlogSecondBoxesDivMob'>
                    <div className='secondBlogSecondBlogBoxesMainDivMob'>
                        {data.slice(0, 4).map(item => (
                            <div className='secondBlogSecondBlogBoxesMob'>
                                <div className='ImageDiv2' key={item.id}>
                                    <img className='secondBlogSecondImage' src={item.attributes.image} />
                                </div>
                                <div className='secondBlogSecondBoxHeaderMob'>
                                    {item.attributes.tittle}
                                </div>
                                <div className='secondBlogSecondBoxHeaderText'>
                                    {item.attributes.category}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='BlogUnderLine'>
                    <div style={{ backgroundColor: '#d7d7d7', width: '90%', height: '1.4px' }}></div>
                </div>
            </div>

            {/* Tab */}
            <div className='secondBlogSecondStartTab d-none d-sm-flex d-lg-none'>
                <div className='secondAreaAddDivTab'>
                    <div className='secondAreaAddMob'>
                        Area For Ad
                    </div>
                </div>
                <div className='secondBlogSecondBoxesDivTab'>
                    <div className='secondBlogSecondBlogBoxesMainDivMob'>
                        <div className='secondBlogSecondTabStyle'>
                        {data.slice(0, 2).map(item => (
                            <div className='secondBlogSecondBlogBoxesTab'>
                                <div className='ImageDiv2' key={item.id}>
                                    <img className='secondBlogSecondImage' src={item.attributes.image} />
                                </div>
                                <div className='secondBlogSecondBoxHeaderMob'>
                                    {item.attributes.tittle}
                                </div>
                                <div className='secondBlogSecondBoxHeaderText'>
                                    {item.attributes.category}
                                </div>
                            </div>
                        ))}
                        </div>
                        <div className='secondBlogSecondTabStyle'>
                        {data.slice(3, 5).map(item => (
                            <div className='secondBlogSecondBlogBoxesTab'>
                                <div className='ImageDiv2' key={item.id}>
                                    <img className='secondBlogSecondImage' src={item.attributes.image} />
                                </div>
                                <div className='secondBlogSecondBoxHeaderMob'>
                                    {item.attributes.tittle}
                                </div>
                                <div className='secondBlogSecondBoxHeaderText'>
                                    {item.attributes.category}
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className='BlogUnderLine'>
                    <div style={{ backgroundColor: '#d7d7d7', width: '90%', height: '1.4px' }}></div>
                </div>
            </div>
        </>
    )
}

export default BlogSeconPageSecond