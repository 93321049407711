import { useEffect, useState } from "react";
import { message } from "antd";
// import API from "../../../config/api";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";

import { Loading3QuartersOutlined } from "@ant-design/icons";

const RegistrationDetails = () => {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  console.log("dataaaa vannooo");

  async function fetchData() {
    try {
      const response = await axios.get(
        "https://semikolen-server-bb1d3cc5f7e3.herokuapp.com/Admission"
      );
      setIsLoading(false);
      setUser(response.data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  }

  return (
    <div className="table-responsive w-100" style={{ height: "100%" }}>
      <h2 className="py-3 ps-2">Registration Details</h2>

      {isLoading ? (
        <div
          style={{
            width: "100%",
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            indicator={
              <Loading3QuartersOutlined
                style={{
                  fontSize: 20,
                  color: "blue",
                  marginRight: 4,
                }}
                spin
              />
            }
          />{" "}
        </div>
      ) : (
        <table className="table table-striped align-self-start table-hover">
          <thead>
            {/* <tr> */}
            <tr>
              <th scope="col">#</th>
              <th scope="col">Full Name</th>
              <th scope="col">DOB</th>
              <th scope="col">Gender</th>
              <th scope="col">State</th>
              <th scope="col">Nationality</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">ResidentialAddress</th>
              <th scope="col">City</th>
              <th scope="col">District</th>
              <th scope="col">Postal Code</th>
              <th scope="col">Year of Completed</th>
              <th scope="col">Specialisation</th>
              <th scope="col">Relevent Qualification</th>
              <th scope="col">Qualification</th>
              <th scope="col">Education</th>
              <th scope="col">Course Name</th>
              <th scope="col">Online or Ofline</th>
              <th scope="col">Prefered Duration</th>
              <th scope="col">Language</th>
              <th scope="col">Comments</th>
              <th scope="col">Image Photo</th>
              <th scope="col">Image id</th>
              <th scope="col">updatedAt</th>
              <th scope="col">createdAt</th>
            </tr>{" "}
            {/* </tr> */}
          </thead>
          <tbody>
            {user?.map((item, index) => (
              <tr key={item.id}>
                {/* <tr key={item.id}> */}
                <td>{index + 1}</td>
                <td>{item.FullName}</td>
                <td>{moment(item.DOB).format("MMMM Do YYYY")}</td>
                <td>{item.Gender}</td>
                <td>{item.State}</td>
                <td>{item.Nationality}</td>
                <td>{item.Email}</td>
                <td>{item.PhoneNumber}</td>
                <td>{item.ResidentialAddress}</td>
                <td>{item.City}</td>
                <td>{item.District}</td>
                <td>{item.PostalCode}</td>
                <td>{item.EduPassout}</td>
                <td>{item.Specialisation}</td>
                <td>{item.ReleventQual}</td>
                <td>{item.Qualification}</td>
                <td>{item.HighQualPss}</td>
                <td>{item.CourseName}</td>
                <td>{item.OnlineOrOfline}</td>
                <td>{item.PreferedBatch}</td>
                <td>{item.Language}</td>
                <td>{item.IdProof}</td>
                <td>{item.ImageUrl}</td>
                <td>{item.ImageUrl2}</td>
                <td>{moment(item.UpdatedAt).format("MMMM Do YYYY")}</td>
                <td>{moment(item.createdAt).format("MMMM Do YYYY")}</td>
                {/* </tr>{" "} */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RegistrationDetails;
