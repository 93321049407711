import React from 'react'
import './BlogLast.css'

function BlogLast() {
  return (
    <div className='LastPageStart'>
        <div className='Last-FirstCard'>
            <div className='Last-CardHeader'>
                Content Header

            </div>
            <div className='Last-FirstCardImage'>

            </div>
            <div className='Last-CardText'>
            Sunset is the time of day when the sun appears to dip below the horizon,
             marking the end of daylight hours and the beginning of the night.
             It is a natural phenomenon that occurs due to the Earth's rotation 
             and its orbit around the sun.
                
            </div>


        </div>
        <div className='Last-SecondCard'>
        <div className='Last-CardHeader'>
        Content Header


            </div>
            <div className='Last-SecondCardImage'>

            </div>
            <div className='Last-CardText'>
            Sunset is known for its beautiful colors, ranging from golden yellows and 
            oranges to deep reds and purples. These colors are caused by the scattering of 
            light in the Earth's atmosphere, which results in the different hues seen during a sunset.
    
            </div>

        </div>
        <div className='Last-ThirdCard'>
        <div className='Last-CardHeader'>
        Content Header


             </div>
            <div className='Last-ThirdCardImage'>

            </div>
            <div className='Last-CardText'>
            Sunset can also have symbolic meanings and be associated with various
             emotions, such as nostalgia, romance, and reflection. It is a time when people often pause
             to appreciate the beauty of nature and reflect on the day that has passed.
    
            </div>

        </div>

    </div>
  )
}

export default BlogLast;