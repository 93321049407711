import React from "react";
import "./Blognavbar.css";
import semiLogoOnly from "../Assets/images/Semikolen logo only.svg";
import semiText from "../Assets/images/Semikolen logo text.svg";
import { Select } from "antd";
import { RxAvatar } from 'react-icons/rx';
import { IoHome } from 'react-icons/io5';
import moment from 'moment';


function Blognavbar() {

  
  return (
    <div>
    <div className="main-nav">
      <div className="semilogos">
        <div className="semiLogoOnly">
          <img src={semiLogoOnly} alt=""/>
        </div>
        <div className="semiTextOnly">
          <img src={semiText} alt=""/>
        </div>
      </div>
      <div className="searchbar-div">
        <input
          type="text"
          id="searchbar-id"
          placeholder="Enter your full name here."
          
        />

        <Select
          // defaultValue="lucy"
          placeholder={"Filter by"}
          style={{
            width: 100,
          }}
          options={[
            {
              label: "Manager",
              options: [
                {
                  label: "Jack",
                  value: "jack",
                },
                {
                  label: "Lucy",
                  value: "lucy",
                },
              ],
            },
            {
              options: [
                {
                  label: "yiminghe",
                  value: "Yiminghe",
                },
              ],
            },
          ]}
        />
      </div>
      <div className="sign-button-div">
      <button
            className="buttonsign" 
          >
            <a href="#">
              <div className='signblog'>Sign In</div>
            </a>
          </button> 

      </div>
      <div className="avathar-div">
        <RxAvatar size={50} color="gray"/>
          
      </div>

      
    </div>
    <div className="navFooter">
      <div className="home-icon-div">
        <IoHome/>
      </div>
      <div className="solution-div activation">
        Solution
      </div>
      <div className="UIUX-div activation">
        UI/UX
      </div>
      <div className="inspire-div activation">
        Inspire
      </div>
      <div className="apps-div activation">
        Apps
      </div>
      <div className="student-div">
        Student Corner
      </div>
      <div className="date-div">
      {moment().format('ll')}
      </div>

    </div>
    </div>
    
  );
}

export default Blognavbar;
