import React, { useState } from "react";
import RegisterStep1 from "./registerStep1";
import RegisterStep2 from "./registerStep2";
import RegisterStep3 from "./registerStep3";
import TermsAndConditions from "./termsAndConditions";
import API from "../Config/api";
import axios from "axios";
import { Await, useNavigate } from "react-router-dom";
import { message } from "antd";

function Register() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const navigation = useNavigate();

  const combineData = (values, imageurl1, imageUrl2) => {
    const newData = {
      ...data,
      ...values,
      imageurl1,
      imageUrl2,
    };
    setData(newData);
  };
  console.log(data, "====================data");
  const onRegister = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: 1,
      FullName: data.full_name,
      DOB: data.dob,
      Gender: data.gender,
      State: data.state,
      Nationality: data.nationality,
      Email: data.email,
      PhoneNumber: data.mobile,
      ResidentialAddress: data.address1 + ", " + data.address2,
      City: data.city,
      PostalCode: data.pin,
      Specialisation: data.specialisation,
      District: data.district,
      EduPassout: data.year_of_comp,
      Qualification: data.qualification,
      HighQualPss: data.education,
      ReleventQual: data.relevent_education,
      CourseName: data.selection_of_course,
      PreferedBatch: data.preferable_duration,
      Language: data.language,
      highQualimg: "",
      IdProof: data.Comments,
      OnlineOrOfline: data.online_or_offline,
      ImageUrl: data.imageUrl2,
      ImageUrl2: data.imageurl1,
    });
    console.log(raw, "raw----------------->>>>>>>>");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://semikolen-server-bb1d3cc5f7e3.herokuapp.com/Admission",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    const mailData = JSON.parse(raw);
    const mailUrl =
      "https://semikolen-server-bb1d3cc5f7e3.herokuapp.com/Admission/createandsentMail";
    fetch(mailUrl, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mailData),
    })
      .then((response) => {
        console.log(
          mailUrl,
          "-----------------mailUrlmailUrlmailUrlmailUrl--.......................>>>>"
        );
        if (response.ok) {
          // window.goBack("/");
        } else {
          throw new Error("failed to add");
        }
      })
      .catch((error) => {
        console.error("error to add=======>>>>>>>>.");
      });
  };

  const BookingData = {
    fullName: "Mishab O M",
    email: "mishabmandan@gmail.om",
  };

  return (
    <div className="registration-form">
      <div className="registration-header"></div>
      <div className="registration-body">
        {step === 0 ? (
          <div className="">
            <TermsAndConditions
              goBack={() => setStep(0)}
              next={() => setStep(1)}
            />
          </div>
        ) : step === 1 ? (
          <div className="">
            <RegisterStep1
              data={BookingData}
              onSubmit={(data) => combineData(data)}
              goBack={() => setStep(0)}
              next={() => setStep(2)}
            />
          </div>
        ) : step === 2 ? (
          <div className="">
            <RegisterStep2
              onSubmit={(data, imageurl, imageUrl2) =>
                combineData(data, imageurl, imageUrl2)
              }
              data={BookingData}
              goBack={() => setStep(1)}
              next={() => setStep(3)}
            />
          </div>
        ) : step === 3 ? (
          <div className="">
            <RegisterStep3
              data={BookingData}
              submitData={() => onRegister()}
              goBack={() => setStep(2)}
              formData={data}
            />
          </div>
        ) : null}
      </div>
      <div className="registration-footer"></div>
    </div>
  );
}

export default Register;
