import React, { useEffect, useState } from 'react'
import './BlogSeconPage.css'
import Blognavbar from '../Blog/Blognavbar'
import Footer from '../components/Footer/Footer'
import moment from 'moment';
import BlogSeconPageSecond from './BlogSeconPageSecond';
import BlogSecondLastPage from './BlogSeconPageLastPage';
import axios from 'axios';



function BlogSeconPage() {

  const [data, setData] = useState([])

  useEffect(() => {
    axios.get('https://suprabhaatham-dev.herokuapp.com/api/semikolens')
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  console.log(data)
  return (
    <div>
      <Blognavbar />
      <div className='BlogSeconPageContainer d-none d-lg-flex'>
        <div className='startSection'>
          <div className='FirstSection'>
            {data.slice(0, 1).map(item => (
              <div className='FirstSectionImageDiv' key={item.id}>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(0, 1).map(item => (
              <div className='headingText' key={item.id}>{item.attributes.tittle}</div>
            ))}
            {data.slice(0, 1).map(item => (
              <div className='paragraphText' key={item.id}>{item.attributes.body}</div>
            ))}
            <div className="dateDiv">
              {moment().format('ll')}
            </div>

            {data.slice(1, 2).map(item => (
              <div className='FirstSectionSecondImageDiv' key={item.id}>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='headingText' key={item.id}>{item.attributes.tittle}</div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='paragraphText' key={item.id}>{item.attributes.body}</div>
            ))}
            <div className="dateDiv">
              {moment().format('ll')}
            </div>
          </div>
          <div className='SecondSection'>
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>MEDIA & ENTERTAINMENT</div>
              {data.slice(0, 1).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(1, 2).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>APPS</div>
              {data.slice(2, 3).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(3, 4).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>MEDIA & ENTERTAINMENT</div>
              {data.slice(4, 5).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(5, 6).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDiv'>
              <div className='SecondSectionSideHeading'>APPS</div>
              {data.slice(6, 7).map(item => (
                <div className='SecondSectionHeading' key={item.id}>{item.attributes.tittle}</div>
              ))}              <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            {data.slice(7, 9).map(item => (
              <>
                <div className='SecondSectionDiv' key={item.id}>
                  <div className='SecondSectionSideHeading'>STARTUPS</div>
                  <div className='SecondSectionHeading' >{item.attributes.tittle}</div>
                  <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
                  <div className='SecondSectionTime'>{moment().format('ll')}</div>
                </div>
                <hr />
              </>
            ))}
          </div>
        </div>
      </div>

      {/* Mob */}
      <div className='BlogSeconPageContainerMob d-flex d-lg-none'>
        <div className='startSectionMob'>
          <div className='FirstSectionMob'>
            {data.slice(0, 1).map(item => (
              <div className='FirstSectionImageDivMob d-block d-sm-none'>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(0, 1).map(item => (
              <div className='FirstSectionImageDivTab d-none d-sm-block d-lg-none'>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(0, 1).map(item => (
              <div className='headingTextMob' key={item.id}>{item.attributes.tittle}</div>
            ))}
            <div className="dateDiv">
              {moment().format('ll')}
            </div>
            <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
            {data.slice(0, 1).map(item => (
              <div className='paragraphTextMob' key={item.id}>{item.attributes.body}</div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='FirstSectionSecondImageDivMob d-block d-sm-none' key={item.id}>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='FirstSectionSecondImageDivTab d-none d-sm-block d-lg-none' key={item.id}>
                <img className='secondBlogImage' src={item.attributes.image} />
              </div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='headingTextMob' key={item.id}>{item.attributes.tittle}</div>
            ))}
            {data.slice(1, 2).map(item => (
              <div className='paragraphTextMob' key={item.id}>{item.attributes.body}</div>
            ))}
          </div>
          <div className='SecondSectionMob'>
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>MEDIA & ENTERTAINMENT</div>
              {data.slice(0, 1).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(1, 2).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>APPS</div>
              {data.slice(2, 3).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(3, 4).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>MEDIA & ENTERTAINMENT</div>
              {data.slice(4, 5).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>STARTUPS</div>
              {data.slice(5, 6).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            <div className='SecondSectionDivMob'>
              <div className='SecondSectionSideHeading'>APPS</div>
              {data.slice(6, 7).map(item => (
                <div className='SecondSectionHeadingMob' key={item.id}>{item.attributes.tittle}</div>
              ))}
              <div className='SecondSectionSubHeadingMob'>KYLE WIGGERS</div>
              <div className='SecondSectionTime'>{moment().format('ll')}</div>
            </div>
            <hr />
            {data.slice(7, 9).map(item => (
              <>
                <div className='SecondSectionDiv' key={item.id}>
                  <div className='SecondSectionSideHeading'>STARTUPS</div>
                  <div className='SecondSectionHeadingMob' >{item.attributes.tittle}</div>
                  <div className='SecondSectionSubHeading'>KYLE WIGGERS</div>
                  <div className='SecondSectionTime'>{moment().format('ll')}</div>
                </div>
                <hr />
              </>
            ))}
          </div>
        </div>
      </div>
      <BlogSeconPageSecond />
      <BlogSecondLastPage />
      <Footer />
    </div>
  )
}

export default BlogSeconPage