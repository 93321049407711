import React from "react";
import "./HDfirstpage.css";
import NavbarContent from "../components/Navbar";
import { mainstyle } from "../Confiq/MainStyle";
import { Button, Col, Row } from "antd";
import HDsecondpage from "./HDsecondpage";
import { Link } from "react-router-dom";
import HDthirdpage from "./HDthirdpage";
import Footer from '../components/Footer/Footer'

function HDfirstpage() {
  return (
    <>
      <div className="start">
        <NavbarContent />
        <Row gutter={0}>
          <Col md={18}>
            <div className="HeroTxt-container">
              <div className="HeroTxt-1">Welcome to your finishing school </div>
              <div className="HeroTxt-main">
                Hire skilled developers
                <br />
                on board
              </div>
              <div className="HeroTxt-sub">
                Building practical software projects with SEMIKOLEN enables
                developers to acquire
                <br />
                tech skills that are marketable.Developers who succesfully
                finish our cutting-
                <br />
                edge project-based program are prepared to assist your business
                in achieving its
                <br />
                maximum impact objectives.
              </div>
              <Link to={"/HDinsidePage"}>
                <Button type="primary" className="GetAProofBtn">
                  Hire a Developer
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <HDsecondpage />
      {/* <HDthirdpage /> */}
      <Footer/>
      {/* // <Footer /> */}
    </>
  );
}

export default HDfirstpage;
