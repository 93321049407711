import React from 'react'
import './Login.css';
import Google from '../Assets/icons-google.png'
import Apple from '../Assets/icons-apple.png'
import Facebook from '../Assets/icons-facebook.png'
import Mail from '../Assets/icons-mail.png'
import { Checkbox } from 'antd';
import SemikolenLogo from '../Assets/Semikolenlogoonly.svg'



function LogIn() {
  return (
    // Mobile
    <>
    <div className="App d-block d-lg-none">
      <main>
        <div className='header'>
          <h2>Sign in</h2>
          <h6>sign in to join with us and < br />have a bright career...</h6>
        </div>
        <section className="services">
          <h3>Your new super-power begins here...</h3>
          <div className='servicesDiv d-flex d-sm-none'>
            <div className='continueDiv'>
              <img src={Google} alt='' />
              <div>Continue With Google</div>
            </div>
            <div className='continueDiv'>
              <img src={Apple} alt='' />
              <div>Continue With Apple</div>
            </div>
            <div className='continueDiv'>
              <img src={Facebook} alt='' />
              <div>Continue With Facebook</div>
            </div>
            <div className='continueDiv'>
              <img src={Mail} alt='' />
              <div>Continue With Mail</div>
            </div>
          </div>

          {/* Tab */}
          <div className='servicesDiv d-none d-sm-flex d-lg-none w-50'>
            <div className='continueDiv'>
              <img src={Google} alt='' />
              <div>Continue With Google</div>
            </div>
            <div className='continueDiv'>
              <img src={Apple} alt='' />
              <div>Continue With Apple</div>
            </div>
            <div className='continueDiv'>
              <img src={Facebook} alt='' />
              <div>Continue With Facebook</div>
            </div>
            <div className='continueDiv'>
              <img src={Mail} alt='' />
              <div>Continue With Mail</div>
            </div>
          </div>

          <div className='checkboxDiv'>
            <div><Checkbox style={{ fontSize: 'x-small' }}> I agree to the Terms & Condition</Checkbox></div>
            <div><Checkbox style={{ fontSize: 'x-small' }}> I want to receive study pathways,free resources<br /> and admission guidance from Cimson<br /> Education Group.</Checkbox></div>
          </div>
          <div className='UnderText'>Already have an account?<b> Log in </b></div>
        </section>
      </main>
      </div>

      {/* Lap */}
      
      <div className="AppLap d-none d-lg-block w-100">
      <main className='ContainerLap d-lg-flex h-100 w-100'>
        <div className='headerLap'>
          <div className='headerLapDiv'>
          <img className='headerLapDivImage' src={SemikolenLogo} alt=''/>
          <h4>Sign in</h4>
          <h6>sign in to join with us and < br />have a bright career...</h6>
          </div>
        </div>
        <section className="servicesLap">
          <h3>Your new super-power begins here...</h3>
          <div className='servicesDiv d-none d-sm-flex w-50'>
            <div className='continueDiv'>
              <img src={Google} alt='' />
              <div>Continue With Google</div>
            </div>
            <div className='continueDiv'>
              <img src={Apple} alt='' />
              <div>Continue With Apple</div>
            </div>
            <div className='continueDiv'>
              <img src={Facebook} alt='' />
              <div>Continue With Facebook</div>
            </div>
            <div className='continueDiv'>
              <img src={Mail} alt='' />
              <div>Continue With Mail</div>
            </div>
          </div>
          <div className='checkboxDiv'>
            <div><Checkbox style={{ fontSize: 'x-small' }}> I agree to the Terms & Condition</Checkbox></div>
            <div><Checkbox style={{ fontSize: 'x-small' }}> I want to receive study pathways,free resources and <br /> admission guidance from Cimson Education Group.</Checkbox></div>
          </div>
          <div className='Under'>Already have an account?<b> Log in </b></div>
        </section>
      </main>
      </div>
    </>
  )
}

export default LogIn