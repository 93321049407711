import ContactUs from "../components/contact/contact";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbar";
import './styles.scss'
const ContactScreen = () => {
  return (
    <div className="contactScreen-main">
      {/* <div style={{marginBottom:"150px"}}></div> */}
      <ContactUs  />
      <Footer />
    </div>
  );
};

export default ContactScreen;
