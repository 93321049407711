import React, { useState, useEffect } from "react";
import "./BlogSeconPageLastPage.css";
import moment from "moment";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function BlogSecondLastPage() {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);
  console.log(data);

  const getUsers = () => {
    axios
      .get("https://suprabhaatham-dev.herokuapp.com/api/semikolens")
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function deleteClick(id) {
    axios.delete(`https://suprabhaatham-dev.herokuapp.com/api/semikolens/${id}`)
    .then((response) => {
      if (response.status === 200) {
        getUsers();
      } else {
        console.log("Delete request failed");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const editClick = (item) => {
    navigate("/BlogCreate", { state: { item } });
  };

  return (
    <>
      {data.slice(0 ,2).map((item) => (
        <div className="blogSecondLastPageContainer d-none d-lg-flex">
          <div className="LastFirstCard">
            <div className="LastCardHeader">
              <div className="headingSection">
                <div className="SecondSectionSideHeading">
                  MEDIA & ENTERTAINMENT
                </div>
                <div className="SecondSectionHeading" key={item.id}>
                  {item.attributes.tittle}
                </div>
              </div>
              <div>
                <div className="SecondSectionSubHeading">KYLE WIGGERS</div>
                <div className="SecondSectionTime">{moment().format("ll")}</div>
              </div>
            </div>
            <div className="LastFirstCardImage" key={item.id}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={item.attributes.image}
              />
            </div>
            <div className="LastCardText" key={item.id}>
              {item.attributes.category}
            </div>
            <div className="delateButton">
             
                <AiFillEdit
                  className="aiFillEdit"
                  onClick={() => editClick(item)}
                />
             
              <MdDelete onClick={() => deleteClick(item.id)} />
            </div>
          </div>
          <div className="BlogUnderLine">
            <div
              style={{
                backgroundColor: "#d7d7d7",
                width: "76%",
                height: "1.7px",
              }}
            ></div>
          </div>
        </div>
      ))}
      {/* Mobile */}
      <div className="blogSecondLastPageContainerMob d-flex d-sm-none">
        {data.slice(0, 2).map((item) => (
          <div className="LastFirstCardMob">
            <div className="LastCardHeaderMob">
              <div className="headingSectionMob">
                <div className="SecondSectionSideHeading">
                  MEDIA & ENTERTAINMENT
                </div>
                <div className="SecondSectionHeading" key={item.id}>
                  {item.attributes.tittle}
                </div>
              </div>
              <div>
                <div className="SecondSectionSubHeading">KYLE WIGGERS</div>
                <div className="SecondSectionTime">{moment().format("ll")}</div>
              </div>
            </div>
            <div className="LastFirstCardImageMob" key={item.id}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={item.attributes.image}
              />
            </div>
            <div className="LastCardTextMob" key={item.id}>
              {item.attributes.category}
            </div>
          </div>
        ))}
      </div>
      {/* Tab */}
      <div className="blogSecondLastPageContainerTab d-none d-sm-flex d-lg-none">
        {data.slice(0, 2).map((item) => (
          <div className="LastFirstCardTab">
            <div className="tabBlogStyle">
              <div className="LastCardHeaderTab">
                <div className="headingSectionTab">
                  <div className="SecondSectionSideHeading">
                    MEDIA & ENTERTAINMENT
                  </div>
                  <div className="SecondSectionHeading" key={item.id}>
                    {item.attributes.tittle}
                  </div>
                </div>
                <div>
                  <div className="SecondSectionSubHeading">KYLE WIGGERS</div>
                  <div className="SecondSectionTime">
                    {moment().format("ll")}
                  </div>
                </div>
              </div>
              <div className="LastFirstCardImageTab" key={item.id}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={item.attributes.image}
                />
              </div>
            </div>
            <div className="LastCardTextMob" key={item.id}>
              {item.attributes.category}
            </div>
          </div>
        ))}
      </div>
      {/* <BlogCreatePage
            editData={editData}/> */}
    </>
  );
}
export default BlogSecondLastPage;
