import React from "react";
import "./Firstpage.scss";
import boyimage from "../../../Assets/images/fuure programmer.png";
import { Container } from "react-bootstrap";

function Firstpage() {
  return (
    <Container fluid>
      <div className="first-page">
        {/* Mobile view starts from here */}
        <div className="question-mobile-div">
          Are you sitting idle and waiting for your true calling?
        </div>
        <div className="second-text-mobile">
          it's time to start from where you stopped and transform your career
          with software development.At our software development education
          organization,we beleive that anyone can learn to code, regardless of
          their background or experience.
        </div>
        <div className="three-info-mobile">
          <div className="three-info">
            <div className="online-div">
              ONLINE & OFFLINE
              <br />
              <h6>1 YEAR</h6>
            </div>
            <div className="trial-div">
              TRIAL SESSION
              <br />
              <h6>FREE</h6>
            </div>
            <div className="placement-div">
              PLACEMENT
              <br />
              <h6>100%</h6>
            </div>
          </div>
        </div>
        <div className="future-main-mobile">Hey there, Future Programmer!</div>
        <div className="embark-text-mobile">
          <div className="embark-contain-mobile">
            Are you ready to embark on a journey that will change your life
            forever?
          </div>
        </div>
        <div className="superpower-mobile">
          Learning to code is like unlocking a secret superpower that lets you
          create amazing things out of thin air!
        </div>
        <div className="boy-image-mobile">
          <img src={boyimage} />
        </div>
        <div className="start-mobile">Start learning with us</div>
        <div className="just-text-mobile">
          Just imagine being able to build your own website,develop your own
          app, or even create your own video game! you'll be like a digital
          wizard, waving your coding wand and making your ideas come to life.
        </div>
        <div className="provide-mobile-div">
          <div className="provide-mobile">
            In our program we trains our students in website Development, Web
            Application Development,Mobile Application Development along with
            providing an opportunity to work on real-world projects.
          </div>
        </div>

        {/* MObile view ends here */}

        <div className="future-main-head">Hey there, Future Programmer!</div>
        <div className="embark-div">
          Are you ready to embark on a journey that will change your life
          forever?
        </div>
        <div className="boy-text">
          <div className="boy-image-div">
            <img src={boyimage} />
          </div>
          <div className="two-text">
            <div className="start-div">Start learning with us</div>
            <div className="just-text-div">
              Just imagine being able to build your own website,develop
              <br />
              your own app, or even create your own video game! you'll be
              <br />
              like a digital wizard, waving your coding wand and making
              <br />
              your ideas come to life.
            </div>
            {/* <div className='provide-div'>
        In our program we trains our students in website<br/>
        Development, Web Application Development,Mobile<br/>
        Application Development along with providing an <br/>
        opportunity to work on real-world projects.
      </div> */}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Firstpage;
