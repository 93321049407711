import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./NavBarModel.css";
import upper from "../../../Assets/images/upper.png";
import { Link } from "react-router-dom";

function NavBarModel(props) {
  return (
    <>
      <Modal
        show={props.visible}
        fullscreen={true}
        onHide={() => props.onCancel()}
        className="modell"
      >
        <Modal.Body className="modell">
          <div className="search-bar">
            <label>
              <input
                type="text"
                id="search-id"
                placeholder="Search in this site"
              />
            </label>
            <button id="serach-id">
              <a href="#">Search</a>
            </button>
          </div>
          <div className="selection">
            <div className="home-div1">
              <a className=" active2" href="#home">
                Home
              </a>
            </div>
            <div className="program-div1">
              <a className=" active2" href="#courses">
                courses
              </a>
            </div>
            <Link to="/HDfirstpage" className="hire-div1 text-decoration-none">
              <div className="hire-div1">
                <a className=" active2">Hire Developer</a>
              </div>
            </Link>
            <Link to="/Blognavbar" className="blog-div1">
              <a className="active2" href="#blog">
                Blog
              </a>
            </Link>
            <div className="about-div1">
              <a className=" active2" href="#about">
                About
              </a>
            </div>
          </div>
          <Link to="/register" className="text-decoration-none">
            <div className="tbutton-div">
              <button className="trial-button">Book Your FREE Trial</button>
            </div>
          </Link>
          <div className="button2-div">
            <Link to="/LogIn" className="text-decoration-none">
              <button className="button2">
                <a href="#">
                  <div className="sign">Sign In</div>
                </a>
              </button>
            </Link>
          </div>
          <div className="button2-div">
            <button className="button2">
              <a href="#">
                <div className="sign">Download Brochure</div>
              </a>
            </button>
          </div>
          <div className="close" onClick={() => props.onCancel()}>
            <img src={upper} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NavBarModel;
