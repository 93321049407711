import "./styles.scss";
// import SemikolenLogoText from "../../../Assets/images/Semikolen logo text.svg";
// import SemikolenLogoOnly from "../../../Assets/images/Semikolen logo only.svg";
// import happy from "../../../Assets/images/shadowedgirl.png";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarContent from "../../../components/Navbar";
// import { Link } from "react-router-dom";
import { Button, Col, Row } from "antd";

function Hero() {
  return (
    <div className="mainContainer">
      <NavbarContent />
      <Row gutter={0}>
        <Col md={18}>
          <div className="HeroTxt-container">
            <div className="HeroTxt-1">Welcome to your finishing school </div>
            <div className="HeroTxt-main">
              Master coding through <br />
              hands-on practice.
            </div>
            <div className="HeroTxt-sub">
              Sharpen coding expertise through hands-on practice, translating
              theory into mastery.<br/> Gain a deeper understanding by directly
              applying concepts, fostering confidence<br/> and skill refinement.
              Embrace this dynamic learning approach for effective coding<br/> 
              proficiency.
            </div>
            <Button type="primary" className="GetAProofBtn">
              Apply Now
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
