import React, { Component } from 'react';
import './CommentBox.css';
import { BiComment } from 'react-icons/bi';

class CommentSection extends Component {
  constructor(props) {
    super(props);
    this.state = { showComments: false, comment: '', comments: [] };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleComments = this.toggleComments.bind(this);
  }

  handleInputChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const newComment = { text: this.state.comment, user: 'User1' };
    this.setState(prevState => ({ comments: [...prevState.comments, newComment], comment: '' }));
  }

  toggleComments() {
    this.setState(prevState => ({ showComments: !prevState.showComments }));
  }

  render() {
    const { comments, showComments } = this.state;
    return (
      <div className="CommentSection">
        <div className="CommentBoxIcon" onClick={this.toggleComments}>
          <BiComment size={20}/>
        </div>
        {showComments && (
          <>
            {comments.length > 0 ? (
              <div className="Comments">
                {comments.map((comment, index) => (
                  <div key={index} className="Comment">
                    <span className="CommentUser">{comment.user}:</span> {comment.text}
                  </div>
                ))}
              </div>
            ) : (
              <div className="NoComments">No comments yet.</div>
            )}
            <form className="CommentBox" onSubmit={this.handleSubmit}>
              <label htmlFor="comment">Add a comment:</label>
              <input
                type="text"
                id="comment"
                value={this.state.comment}
                onChange={this.handleInputChange}
              />
              <button type="submit">Submit</button>
            </form>
          </>
        )}
      </div>
    );
  }
}

export default CommentSection;
