import React, { useState } from "react";
import NavbarContent from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import "./BlogCreatePage.css";
import { Input, message, Form, Button } from "antd";
import S3con from "./S3con";
import { uploadFile } from "react-s3";
import axios from "axios";
import { Buffer } from "buffer";
import { useLocation } from "react-router-dom";

window.Buffer = Buffer;

const { TextArea } = Input;

function BlogCreatePage() {
  const [imageURL, setImageURL] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  console.log(location);

  const config = {
    bucketName: S3con.bucketName,
    region: S3con.region,
    accessKeyId: S3con.accessKeyId,
    secretAccessKey: S3con.secretAccessKey,
  };
 
  //create a new entry
  const createItem = async (val) => {
    console.log("createItem======>");
    console.log(val);
      uploadFile(selectedFile, config)
      .then((data) => {
        console.log("==s3=============>", data);
        setImageURL(data?.location);
        console.log(data);
      })
      .catch((err) => console.error("err================>", err));
    let data = {
      data: {
        tittle: val.tittle,
        head: val.head,
        image: imageURL,
        body: val.body,
        category: val.category,
      },
    };
    console.log("====data==========")
    console.log("val.tittle========>",val)
    console.log(data)
    console.log("=======data=======")

    axios
      .post("https://suprabhaatham-dev.herokuapp.com/api/semikolens", data)
      .then((response) => {
        console.log("post response==================>", response);
        message.success("Blog created successfully");
        setSuccess(true);
      })
      .catch((error) => {
        console.log("Error===============================>", error);
        message.error("This is an error message");
        setError(true);
      })
      .finally(() => setLoading(false));
    setImageURL("");
    setSelectedFile();
  };

  // update content
  const updateItem = async (id,val) => {
    console.log(val);
    let data = {
      data: {
        tittle: val.tittle,
        head: val.head,
        image: val.image,
        body: val.body,
        category: val.category,
      },
    };
    try {
      const response = await axios.put(
        `https://suprabhaatham-dev.herokuapp.com/api/semikolens/${id}`,data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("post response==================>", response);
      message.success("Blog update successfully");
      setSuccess(true);
    } catch (error) {
      console.log("Error===============================>", error);
      message.error("This is an error message");
      setError(true);
    }
  };
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onFinish = (values) => {
   
    setLoading(true);
    if (location.state) {
      updateItem(location.state.item.id,values)
      console.log("location.data =======>", location.data);
    } else {
      createItem(values);
    }

   
  };



  return (
    <div>
      <NavbarContent />
      <Form
        initialValues={{
          tittle: location.state && location.state.item.attributes.tittle,
          head: location.state && location.state.item.attributes.head,
          imageURL: location.state && location.state.item.attributes.image,
          body: location.state && location.state.item.attributes.body,
          category: location.state && location.state.item.attributes.category,
        }}
        onFinish={onFinish}
      >
        <div className="createBlogContainer">
          <div className="createBlogMainDiv">
            <div className="createBlogHeading">Create New Blog</div>

            <div className="createBlogTitleSection">
              Blog Title
              <Form.Item name="tittle">
                <Input
                  className="createBlogTitleInput"
                  placeholder="Type Title"
                />
              </Form.Item>
            </div>
            <div>
              <div
                className="createBlogImage"
                name="image"
                label="Include snaps.!"
              >
                <Form.Item name="image">
                  <Input
                    className="createBlogImageInput"
                    type="file"
                    accept="image/*, video/*"
                    onChange={handleFileInput}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="createBlogTitleSection">
              Head
              <Form.Item name="head">
                <Input
                  className="createBlogTitleInput"
                  placeholder="Type Title"
                />
              </Form.Item>
            </div>
            <div className="createBlogTitleSection">
              Message
              <Form.Item name="body">
                <TextArea
                  rows={4}
                  placeholder="Enter Message"
                />
              </Form.Item>
            </div>
            <div className="createBlogTitleSection">
              Category
              <Form.Item name="category">
                <Input
                  className="createBlogTitleInput"
                  placeholder="Type Title"
                />
              </Form.Item>
            </div>
            <div className="createBlogTitleSection">
              <Button className="createBlogButton" htmlType="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <Footer />
    </div>
  );
}
export default BlogCreatePage;
