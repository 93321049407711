import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Select, Upload } from "antd";
import "./styles.css";
import { BiArrowBack } from "react-icons/bi";
import { BsUpload } from "react-icons/bs";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom"; // If you're using React Router for navigation
import { DatePicker, Space } from "antd";
// import RegisterStep2 from "./registerStep2";

const RegisterStep1 = (props) => {
  const { Option } = Select;
  const [fullName, setFullName] = useState("");
  const [nationality, setNationality] = useState("");

  const onNextClick = (values) => {
    props.onSubmit(values);
    console.log(values);
    props.next();
  };

  return (
    <div className="">
      <Form onFinish={onNextClick}>
        <div className="RegisterMain-Div">
          <div className="register-header">Personal Info</div>
          <br />
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Full Name</div>
                <Form.Item
                  name="full_name"
                  rules={[{ required: true, message: "Enter Your Full Name" }]}
                >
                  <Input
                    // value={"fullName"}
                    // value={props.data.fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    bordered={false}
                    defaultValue={"kkkk"}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Date Of Birth</div>
                <Form.Item name="dob" rules={[{ required: true }]}>
                  <DatePicker bordered={false} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </Col>

            {/* <Space direction="vertical">
              <DatePicker onChange={onChange} />
              <DatePicker onChange={onChange} picker="week" />
              <DatePicker onChange={onChange} picker="month" />
              <DatePicker onChange={onChange} picker="quarter" />
              <DatePicker onChange={onChange} picker="year" />
            </Space> */}

            {/* <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Date Of Birth</div>
                <Form.Item name="dob" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Gender</div>
                <Form.Item name="gender" rules={[{ required: true }]}>
                  <Select bordered={false}>
                    <Option key={1} value={"male"}>
                      Male
                    </Option>
                    <Option key={2} value={"female"}>
                      Female
                    </Option>
                    <Option key={3} value={"other"}>
                      Other
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Nationality</div>
                <Form.Item
                  onChange={(e) => setNationality(e.target.value)}
                  value={nationality}
                  name="nationality"
                  rules={[{ required: true }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Email</div>
                <Form.Item
                  name="email"
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Mobile</div>
                <Form.Item
                  name="mobile"
                  rules={[{ required: true, type: "mobile" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Address Line 1</div>
                <Form.Item
                  name="address1"
                  rules={[{ required: true, type: "address1" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Address Line 2</div>
                <Form.Item
                  name="address2"
                  rules={[{ required: true, type: "address2" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">City</div>
                <Form.Item
                  name="city"
                  rules={[{ required: true, type: "city" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">District</div>
                <Form.Item name="district" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">State</div>
                <Form.Item
                  name="state"
                  rules={[{ required: true, type: "state" }]}
                >
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="FormElement">
                <div className="FormLabel">Pincode</div>
                <Form.Item name="pin" rules={[{ required: true }]}>
                  <Input bordered={false} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <br />
          <Row>
            <Col md="3"></Col>
            <Col md="3">
              <div
                onClick={() => props.goBack()}
                className="register-back-button"
              >
                Back
              </div>
            </Col>
            <br />
            <br />
            <br />

            <Col md="3">
              <Form.Item>
                <button
                  onClick={() => {
                    console.log("");
                  }}
                  className="register-next-button"
                  // onClick={Useradmindata}
                >
                  Next
                </button>
              </Form.Item>
            </Col>
            <Col md="3"></Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default RegisterStep1;
