import React from "react";
import "./styles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Card } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
// import { Pagination } from "swiper";
import { ClientsInfo } from "./ClientsInfo";
const Testimonials = () => {
  //   const images = [
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4a0mg0wtcQhJCF-QwbK6eBmA7IS4hK0Zgob5rgyCzyU2BsFfc8Nsy5WEAIT7edYGTVUM&usqp=CAU",
  //     // Mars,
  //     // Mondelez,
  //     // MCKinsey,
  //     // Jashanmal,
  //     // Chalhoub,
  //     // Dhl,
  //     // Patchi,
  //     // KenyaAir,
  //     // Grassavoye,
  //     // Buylebanese,
  //     // Suprabhaatham,
  //     // Taxgo,
  //     // Saharicargo,
  //     // // Waynroots,
  //     // // AandG,
  //     // Parcelking,
  //   ];
  //   const settings = {
  //     autoplay: true,
  //     autoplaySpeed: 3000,
  //     infinite: true,
  //     speed: 500,
  //     slidesToScroll: 1,
  //   };
  //   const mobileMediaQuery = window.matchMedia("(max-width: 768px)");

  //   if (mobileMediaQuery.matches) {
  //     settings.slidesToShow = 2;
  //   } else {
  //     settings.slidesToShow = 1;
  //   }

  //   return (
  //     <div className="testimonial-container">
  //       <div >
  //         <Slider {...settings} className="slide">
  //           {images.map((image, index) => (
  //             <div key={index} className="slickImages-container">
  //               <Card>

  //               </Card>

  //               {/* <img className="slickImages" src={image} alt={`Image ${index}`} /> */}
  //             </div>
  //           ))}
  //         </Slider>

  //   </div>
  // </div>
  return (
    <>
      <div className="testimonial-container">
        <h2 className="section__title">Voices of tech journey</h2>
        <div className="section__subtitle">
          Delve into testimonials that capture the transformative impact of our<br/>
          education center, where success stories come to life.
        </div>
        <section className="testimonial container section" id="testimonials">
          <Swiper
            className="testimonial__container"
            loop={true}
            grabCursor={true}
            spaceBetween={24}
            autoplay
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              576: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 48,
              },
            }}
            // modules={[Pagination]}
          >
            {ClientsInfo.map(({ id, image, title, description }) => {
              return (
                <SwiperSlide className="testimonial__card" key={id}>
                  <img
                    src={image}
                    alt="testimonial__img"
                    className="testimonial__img"
                  />
                  <h3 className="testimonial__name">{title}</h3>
                  <p className="testimonal__description">{description}</p>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      </div>
    </>
  );
};

export default Testimonials;
