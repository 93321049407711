import { mainstyle } from "../../Confiq/MainStyle";
import { Button } from "react-bootstrap";
import cls from "./index.module.css";
function FooterBanner(props) {
  return (
    <div className="container h-100 px-0 py-5">
      <h4 className={`mt-2 text-center fs-2 fw-bold ${cls["title-main"]}`}>
        {props.title} <br /> {props.subtitle}
      </h4>
      <div className="text-center">
        <Button type="primary" style={mainstyle.SecondaryButton}>
          Contact Us
        </Button>
      </div>
    </div>
  );
}
export default FooterBanner;
