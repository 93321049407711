import React from "react";
import Footer from "../components/Footer/Footer";
import Firstpage from "./components/Firstpage/Firstpage";
import Secondpage from "./components/Second page/Secondpage";
import Thirdpage from "./components/Third Page/Thirdpage";
import CoursesTrial from "./components/coursesTrial/coursesTrial";
import Hero from "./components/hero";
import Testimonials from "./components/testimonials";

const Home = () => {
  return (
    <div>
      <Hero />
      <Firstpage />
      <Secondpage />
      <Thirdpage />
      <Testimonials />
      <CoursesTrial />
      <Footer />
    </div>
  );
};

export default Home;
