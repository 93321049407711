import React from 'react'
import './HDsecondpage.css'
import grow from "../Assets/images/grow.svg"
import hands from "../Assets/images/Handson.svg"
import dynamic from "../Assets/images/Dynamic.svg"

function HDsecondpage() {
  return (
    <div className='fullpage-second'>
        <div className='HDsecondpage-header'>
            Why hire a Certified Developer? 
        </div>
        <div className='HDthree-boxes'>
        <div className='HDthree-boxes-div'>
            <div className='HDfirst-box'>
                <div className='HDinside-div'>
                    <img src={hands}/>
                </div>
                <div className='HDlearn-text'>
                        Hands-on development experience
                    </div>
                    <div className='HDbig-text'>
                        Our developers learn with real experience of<br/>
                        building multiple tech projects in an actual<br/>
                        developer environment, not just fast forwarding<br/>
                        videos.

                    </div>
                

                    </div>
                    <div className='HDsecond-box'>
                    <div className='HDinside-div'>
                    <img src={dynamic}/>
                    </div>
                    <div className='HDlearn-text-second'>
                        Dynamic problem solving skills
                    </div>
                        <div className='HDbig-text'>
                        Our project-based curriculum exposes developers<br/>
                        to a variety of production-like scenarios that<br/>
                        hone their real-world problem solving skills.
                        
                        </div>

                        </div>
                        <div className='HDthird-box'>
                            <div className='HDinside-div'>
                            <img src={grow}/>
                            </div>
                            <div className='HDlearn-text-third'>
                            Hunger to learn and grow
                        
                    </div>
                        <div className='HDbig-text'>
                        Learning by building is not easy.Our developers<br/>
                        graduate after 500+ hours of active project-led<br/>
                        learning,which requires focus and dedication.<br/>
                        Start Hiring
                        </div>
                

                            </div>
                        
        </div>

        </div>
        <div className='percentage-numbers'>
            <div className='percentage-div'>
                <div className='reduction-div'>
                80%
                <div className='text-reduction'>
                    <p><b>Reduction </b> in hiring cycle</p>
                </div>
                </div>
                <div className='higher-div'>
                    35%
                <div className='text-reduction'>
                    <p><b>Higher </b>conversion rate</p>
                </div>
                </div>
                <div className='no-fee'>
                    ₹0
                <div className='text-reduction'>
                    <p><b>No </b>hiring fees</p>
                </div>
                    

                </div>

            </div>
        </div>

    </div>
  )
}

export default HDsecondpage;