import React from "react";
import { Col, Row } from "react-bootstrap";
import { Card, Button } from "antd";
import cls from "./index.module.css";
import FullStack from "../../Assets/images/full-stack.jpg";
import MobileApp from "../../Assets/images/mobileapp.jpg";
import Backend from "../../Assets/images/bakend.jpg";
import SoftWare from "../../Assets/images/soft-ware-tester.jpg";
import Ai from "../../Assets/images/AItools.jpg";
import WebDes from "../../Assets/images/web-desighner.jpg";
import Footer from "../Footer/Footer";
import Digital from "../../Assets/images/digital-marketing.jpg";
import Kids from "../../Assets/images/kids-coding.jpg";
import { useNavigate } from "react-router-dom";
import NavbarContent from "../Navbar";

function CoursesPage() {
  const { Meta } = Card;
  const navigate = useNavigate();
  let obj = [
    {
      image: "https://images.pexels.com/photos/8062282/pexels-photo-8062282.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      head: "Full Stack Development",
      content:
        "Proficiency in both front-end and back-end technologies is vital for a well-rounded developer. Experts in this domain create web and mobile applications, addressing user interfaces and server-side functionality for holistic development.",
      mode: "Mode Off Class: Offline & online",
      theme: "Duration: 6   Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 1.5 LAKH",
      path: "/FullStackDev",
    },
    {
      image: "https://images.pexels.com/photos/5926389/pexels-photo-5926389.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Mobile App With React Native",
      content:
        "Embark on the journey of mobile app development with React Native. Learn to construct cross-platform applications for iOS and Android using JavaScript, gaining the proficiency to craft responsive, high-performance mobile apps from a unified codebase.",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 1 LAKH",
    },

    {
      image: "https://images.pexels.com/photos/4974915/pexels-photo-4974915.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Backend Development",
      content:
        "Developers work on databases, server scripting, and APIs, crafting server-side logic and infrastructure to drive web and mobile applications. They emphasize server-side technologies to ensure robust and efficient application functionality",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 50K",
      path: "BackendDevelopment",
    },
    {
      image: "https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Web Designing",
      content:
        "Creating visually appealing and user-friendly websites is the essence of this craft. Designers employ HTML, CSS, and graphic design expertise to fashion layouts, colors, and elements that ensure a seamless and captivating user experience..",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 50K",
      path: "/WebDesigning",
    },
    {
      image: "https://images.pexels.com/photos/16094044/pexels-photo-16094044/free-photo-of-a-man-is-sitting-at-a-table-with-a-laptop.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Prompt engineering With AI Tools",
      content:
        "Leveraging AI tools, content creation optimization is achieved. AI-driven tools generate customized cues, enhancing efficiency and effectiveness across multiple applications, simplifying content development and increasing user engagement.",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 50K",
    },
    {
      image: "https://images.pexels.com/photos/4709285/pexels-photo-4709285.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      head: "Kids Coding",
      content:
        "Engaging in interactive tasks, children delve into the world of programming. They acquire fundamental coding skills, fostering creativity and problem-solving abilities while building a solid foundation for future tech comprehension.",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 5K",
      path: "/KidsCoding",
    },
    {
      image: "https://images.pexels.com/photos/3194523/pexels-photo-3194523.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Digital Marketing",
      content:
        "Online marketing utilizes web platforms to exhibit products and services, employing techniques such as SEO, social media, email, and PPC advertising to captivate specific audiences, nurturing brand recognition and boosting conversion rates.",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 50K",
      path: "/DigitalMarketing",
    },
    {
      image: "https://images.pexels.com/photos/4050290/pexels-photo-4050290.jpeg?auto=compress&cs=tinysrgb&w=600",
      head: "Software Testing&Automation",
      content:
        "Professionals in this field expertly blend automated tools and manual techniques, effectively pinpointing and rectifying issues, leading to a substantial enhancement in software reliability and performance for optimal results..",
      mode: "Mode Off Class:  Offline & online",
      theme: "Duration: 6 Months",
      advantage: "Benefit: 6 Month Internship with stipend",
      fees: "Course fee: 50K",
    },
  ];
  const handleChange = (item) => {
    console.log("=========item========", item);
    navigate(item?.path);
  };
  return (
    <div>
      <NavbarContent />
      <div className={cls["course-page"]}>
        <Row>
          {obj.map((item) => (
            <Col md={3} className={cls["course-colum"]}>
              <Card
                hoverable
                style={{ width: "90%", marginLeft: "5%" }}
                cover={<img alt="example" src={item.image} />}
              >
                <Meta
                  className={cls["course-text"]}
                  title={item?.head}
                  description={item?.content}
                />
                <br />
                <div className={cls["course-mode"]}>
                  {item?.mode}
                  <div>{item?.theme}</div>
                  <div>{item?.advantage}</div>
                  <div>{item?.fees}</div>
                </div>
                <Button
                  onClick={() => handleChange(item)}
                  style={{ height: "40px", width: "100px" }}
                  className={cls["cardBtn"]}
                >
                  View More
                </Button>
                         
              </Card>
            </Col>
          ))}
        </Row>
      </div>
        <Footer />
    </div>
  );
}
export default CoursesPage;
