import React, { Component } from 'react';
import './LoveButton.css';
import { AiOutlineHeart } from 'react-icons/ai';

class LoveButton extends Component {
  constructor(props) {
    super(props);
    this.state = { loves: 0 };
    this.handleLoveClick = this.handleLoveClick.bind(this);
  }

  handleLoveClick() {
    this.setState(prevState => ({ loves: prevState.loves + 1 }));
  }

  render() {
    return (
      <div className="LoveButton">
        <button onClick={this.handleLoveClick}>
          <AiOutlineHeart size={20}/>
        </button>
        <span className="LoveCount">{this.state.loves}</span>
      </div>
    );
  }
}

export default LoveButton;
