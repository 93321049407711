import { Button } from "antd";
import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { IoMenu } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import SemikolenLogoOnly from "../../Assets/images/Semikolen logo only.svg";
import SemikolenLogoText from "../../Assets/images/Semikolen logo text.svg";
import { mainstyle } from "../../Confiq/MainStyle";
import NavBarModel from "../../home/components/hero/NavBarModel";
import "./Navbar.scss";

const NavbarContent = () => {
  const navigation = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const options = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Courses",
      path: "/CoursesPage",
    },
    {
      name: "Hire a Developer",
      path: "/hireAdeveloper",
    },
    {
      name: "Contact",
      path: "/contact",
    },
    {
      name: "About",
      path: "/contact",
    },
  ];

  return (
    <>
      <div className="toggler-div" onClick={showModal}>
        <IoMenu color="white" size={25} />
      </div>
      <nav className="navbar-container">
        <div className={"logo-container"}>
          <img
            className="header-logo1"
            onClick={() => navigation("/")}
            src={SemikolenLogoOnly}
          />
          <img
            className="header-logo2"
            onClick={() => navigation("/")}
            src={SemikolenLogoText}
          />
        </div>
        <ul className="header-option-container">
          {options?.map((item) => (
            <li key={item?.id}>
              <div className="options" onClick={() => navigation(item?.path)}>
                {item?.name}
              </div>
            </li>
          ))}
          <li className="signIn-btn-container">
            <button className="quote-nav-btn">Book a Free Trial</button>
          </li>
        </ul>
      </nav>
      {/* <CgMenu
        className="mobile-toggle"
        size={22}
        color="#ffffff"
        onClick={() => setIsOpen(true)}
      /> */}
      {isModalOpen ? (
        <NavBarModel visible={isModalOpen} onCancel={handleCancel} />
      ) : null}
    </>
  );
};

export default NavbarContent;
