import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

// import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBBadge,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import "./navbar.css";

import { FaCar } from "react-icons/fa";
import RegistrationDetails from "../../datas";

const SidebarNavigation = () => {
  return (
    <div>
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#00277f"
        className=""
        maxWidth="300px"
        minWidth="80px"
        breakpoint={768}
        toggled
      >
        <CDBSidebarHeader
          prefix={<i className="fa fa-bars" />}
          className="border-bottom"
        >
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              height: "30px",
              width: "20%",
            }}
          >
            <h6 className="ms-2" style={{ color: "white" }}>
              Semikolen ™
            </h6>
          </div>
        </CDBSidebarHeader>
        <CDBSidebarContent className="sidebar-content mx-0">
          <CDBSidebarMenu>
            <NavLink
              style={({ isActive }) =>
                isActive
                  ? { fontWeight: "bolder", color: "#FFFF99" }
                  : undefined
              }
            >
              <CDBSidebarMenuItem
                icon="table"
                iconSize="lg"
                className="nav-link nav-link-hover"
              >
                Login
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarHeader>
          <div className="container align-items-end ">
            <p
              className="text-wrap fw-normal"
              style={{ fontSize: "12px", color: "white" }}
            >
              {" "}
              Copyright © Semikolen. All rights reserved.
            </p>
          </div>
        </CDBSidebarHeader>
      </CDBSidebar>
    </div>
  );
};

export default SidebarNavigation;
