import React from "react";
import "./coursesTrial.css";
import { Link } from "react-router-dom";
import { Button } from "antd";

function CoursesTrial() {
  return (
    // Mobile
    <>
      <div className="container-fluid ">
        <div className="coursesTrialContainer d-flex justify-content-center align-items-center d-sm-none">
          <div className="coursesTrialMainDiv">
            <div className="coursesTrial d-flex">
              <h1>
                Join us in our educational program to enhance your career
                prospects and secure a better future for yourself.
              </h1>
            </div>
            <div className="coursesTrialButton">
              <div className="coursesTrialButtonMain">
                <Link className="coursesTrialButtonMainLink" to="/terms">
                  <Button type="primary" block>
                    Book your FREE {"Trial >"}
                  </Button>
                </Link>
                <Link className="coursesTrialButtonMainLink" to="/ExporeCoress">
                  <Button
                    className="coursesTrialSecondButton"
                    style={{ backgroundColor: "white" }}
                  >
                    Expore couress
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Tab */}
        <div className="coursesTrialContainer d-none d-sm-flex justify-content-center align-items-center d-lg-none">
          <div className="coursesTrialMainDivTab">
            <div className="coursesTrial d-flex">
              <h1>
                Join us in our educational program to enhance your career
                prospects and secure a better future for yourself.
              </h1>
            </div>
            <div className="coursesTrialButtonTab">
              <div className="coursesTrialButtonMainTab">
                <Link className="coursesTrialButtonMainLinkTab" to="/terms">
                  <Button className="coursesTrialButtonTab" type="primary">
                    Book your FREE {"Trial >"}
                  </Button>
                </Link>
                <Link
                  className="coursesTrialSecondButtonMainLinkTab"
                  to="/ExporeCoress"
                >
                  <Button
                    className="coursesTrialSecondButtonTab"
                    style={{ backgroundColor: "white" }}
                  >
                    Expore couress
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Lap */}
        <div className="coursesTrialContainer d-none d-lg-flex justify-content-center align-items-center">
          <div className="coursesTrialMainDivLap">
            <div className="coursesTrial d-flex">
              <h1>
                Join us in our educational program to enhance your career
                prospects and
                <br /> secure a better future for yourself.
              </h1>
            </div>
            <div className="coursesTrialButtonLap">
              <div className="coursesTrialButtonMainTab">
                <Link className="coursesTrialButtonMainLinkLap" to="/terms">
                  <Button className="coursesTrialButtonsLap" type="primary">
                    Book your FREE {"Trial >"}
                  </Button>
                </Link>
                <Link
                  className="coursesTrialSecondButtonMainLinkLap"
                  to="/ExporeCoress"
                >
                  <Button
                    className="coursesTrialSecondButtonsLap"
                    style={{ backgroundColor: "white" }}
                  >
                    Expore couress
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoursesTrial;
