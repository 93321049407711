import React, { useState, useEffect } from "react";
import './Splash.css'
import Logo from '../Assets/images/Semikolen logo only.svg'


function Splash() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate delay for splash screen
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Hide loading element on page load
    window.addEventListener("load", () => {
      const loadingElement = document.querySelector(".loading");
      loadingElement.classList.add("display-none");
    });
  }, []);

  return (
    <div className="App">
    <header  className={`loading ${loading ? "" : "display-none"}`}>
      <img src={Logo} className="App-logo" alt="logo" />
      <div class="text" data-text="Searching"></div>
      <div id="loader">
      <div class="text" data-text="SEMIKOLEN"></div>
    </div>
    </header>
  </div>
  );
}

export default Splash;
