import React from "react";
import SemikolenLogo from "../Assets/Semikolenlogoonly.svg";
import "./FreeTrial.css";
import { Select, Input, Checkbox, Button, Form, Upload } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import TermsAndConditions from "./TermsConditions";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import InformationFirstStep from "./AdditionalInformationFirstStep";

const BookYourFreeTrial = () => {
  const [selectedState, setSelectedState] = useState(null);
  const [study, setStudy] = useState();
  const [name, setName] = useState();
  const [qualification, setQualification] = useState();
  const [Country, setCountry] = useState();
  const [City, setCity] = useState();
  const [email, setEmail] = useState();
  const [value, setValue] = useState();

  const navigation = useNavigate();

  const click = () => {
    navigation("/InformationFirstStep", {
      state: { mm: "mishab" },
    });
  };

  const mobile = () => {
    navigation("/RegisterMobileSecondStep", {});
  };
  const tablet = () => {
    navigation("/TabletSecondPage", {});
  };
  const indiaStates = [
    { value: "1", label: "python" },
    { value: "2", label: "Java" },
    { value: "3", label: "JS" },
    { value: "4", label: "React" },
    { value: "5", label: "React Native" },
  ];

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  //   const messageString = JSON.stringify(messageObj);
  //   const encodedMessageString = encodeURIComponent(messageString);

  //   // const encodedMessageString = encodeURIComponent(JSON.stringify(messageObj));
  //   const phone = '9605583291';
  //   const baseUrl = 'https://api.whatsapp.com/send/';
  //   const url = `${baseUrl}?phone=${phone}&text=${encodedMessageString}`;

  //   window.open(url);
  // };

  const handleWhatsAppClick = () => {
    const messageObj = {
      study: selectedState,
      name: name,
      qualification: qualification,
      Country: Country,
      City: City,
      email: email,
      Phone: value,
    };

    const messageString = Object.entries(messageObj)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");

    const encodedMessageString = encodeURIComponent(messageString);

    const phone = "9895145275";
    const baseUrl = "https://api.whatsapp.com/send/";
    const url = `${baseUrl}?phone=${phone}&text=${encodedMessageString}`;

    window.open(url);
  };
  // const notWork = selectedState && study && name && qualification && Country && City && email && value;

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    // Mobile
    <>
      <div className="AppSignIn d-block d-lg-none">
        <main>
          {/* <TermsAndConditions /> */}
          <div className="headerSignIn">
            <h2>Join with us...</h2>
            <h6>
              Submit your queries here and we <br />
              will get to you as soon as
              <br />
              possible
            </h6>
          </div>
          <section className="servicesFrrTrial">
            <Form className="servicesDivSignIn d-flex d-sm-none ">
              <Form.Item label="Full Name">
                <Input
                  required
                  placeholder="Type your Full Name here."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Gender">
                <Input
                  required
                  placeholder="Type your Gender here."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Date Of Birth">
                <Input
                  placeholder="Type your Date Of Birth"
                  // value={qualification}
                  onChange={(e) => setQualification(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Nationality">
                <Input
                  placeholder="Type your Nationality"
                  value={Country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email Id">
                <Input
                  placeholder="Type your Email Id"
                  value={City}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Residential Address">
                <Input
                  placeholder="Enter Your Residential Address"
                  // value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Mobile Number">
                <PhoneInput
                  placeholder="XXXX XXX XXX"
                  defaultCountry="IN"
                  value={value}
                  onChange={setValue}
                />
              </Form.Item>
              <Form.Item label="District">
                <Input
                  placeholder="Enter Your District"
                  // value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Pincode">
                <Input
                  placeholder="Enter Your Pincode"
                  // value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Country">
                <Input
                  placeholder="Enter Your Country"
                  // value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              {/* <Form.Item>
                <Button
                  className="SignInButton"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item> */}
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {/* <Navigate to="/InformationFirstStep" />; */}
                {/* <Link to="/InformationFirstStep"> */}
                <div style={{ height: "10px" }}>
                  <Form.Item>
                    <Button
                      className="NextButton"
                      type="primary"
                      htmlType="submit"
                      style={{ height: "30px" }}
                      onClick={mobile}
                    >
                      Next...
                    </Button>{" "}
                  </Form.Item>
                </div>
                {/* </Link> */}
              </div>
            </Form>

            {/* tablet */}
            <div className="servicesDivSignIn d-none d-sm-flex d-lg-none w-50">
              {/* <TermsAndConditions /> */}

              <div className="tabletview">
                <Form>
                  <Form.Item name="course" label="Full Name">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Type Your Full Name"
                      onChange={handleStateChange}
                    >
                      {/* Your Select options */}
                    </Select>
                  </Form.Item>

                  <Form.Item name="name" label="Date Of Birth">
                    <Input placeholder="Type your Date Of Birth." />
                  </Form.Item>

                  <Form.Item name="qualification" label="Gender">
                    <Input placeholder="Type your Gender" />
                  </Form.Item>

                  <Form.Item name="country" label="Email Id">
                    <Input placeholder="Type Your Email Id" />
                  </Form.Item>

                  <Form.Item name="city" label="Residential Address">
                    <Input placeholder="Type your Residential Address" />
                  </Form.Item>

                  <Form.Item name="email" label="City">
                    <Input placeholder="Type Your City" />
                  </Form.Item>

                  <Form.Item name="email" label="Pincode">
                    <Input placeholder="Type Your Pincode" />
                  </Form.Item>

                  <Form.Item name="mobileNumber" label="Mobile Number">
                    <PhoneInput
                      placeholder="XXXX XXX XXX"
                      defaultCountry="IN"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="SignInButton"
                      type="primary"
                      // onClick={handleWhatsAppClick}
                      htmlType="submit"
                      onClick={tablet}
                    >
                      Next...
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {/* <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "end",
                }}
              > */}
              {/* <Navigate to="/InformationFirstStep" />; */}
              {/* <Link to="/InformationFirstStep"> */}
              {/* <div style={{ height: "10px" }}>
                  <Form.Item>
                    <Button
                      className="NextButton"
                      type="primary"
                      htmlType="submit"
                      style={{ height: "30px" }}
                      onClick={mobile}
                    >
                      Next...
                    </Button>{" "}
                  </Form.Item>
                </div> */}
              {/* </Link> */}
              {/* </div> */}
            </div>

            {/* <div className="checkboxDivSignIn">
              <div>
                <Checkbox style={{ fontSize: "x-small" }}>
                  {" "}
                  I agree to the Terms & Condition
                </Checkbox>
              </div>
              <div>
                <Checkbox style={{ fontSize: "x-small" }}>
                  {" "}
                  I want to receive study pathways,free resources and <br />{" "}
                  admission guidance from Cimson Education Group.
                </Checkbox>
              </div>
            </div>
            <div className="UnderTextSignIn">
              Already have an account?<b> Log in </b>
            </div> */}
          </section>
        </main>
      </div>

      {/* Lap */}
      <div className="AppLap d-none d-lg-block w-100">
        <div className="headerSignIn">
          <h2>Join with us...</h2>
          <h6>
            Submit your queries here and we <br />
            will get to you as soon as
            <br />
            possible
          </h6>
        </div>
        <main className="ContainerLap d-lg-flex h-100 w-100">
          {/* <div className="headerLap">
            <div className="headerLapDiv">
              <img className="headerLapDivImage" src={SemikolenLogo} alt="" />
              <h4>Join with us...</h4>
              <h6>
                Submit your queries here and we <br /> will get back to you as
                soon as <br /> possible
              </h6>
            </div>
          // </div> */}

          <section className="servicesSignInLap">
            <div className="servicesMainDivSignInLap">
              <div style={{ width: "70%" }}>
                <Form>
                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="Full Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Full Name",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Full Name</div>
                        <Input
                          placeholder="Enter Your Full Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="SignInInput"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <div>
                        <ImgCrop rotationSlider>
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                          >
                            {fileList.length < 1 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </div>
                    </div>
                  </div>

                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="Date Of Birth"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your DOB",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Date Of Birth</div>
                        <Input
                          placeholder="Enter Your DOB"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="SignInInput">
                      <Form.Item
                        name="Gender"
                        rules={[
                          {
                            required: true,
                            message: "Type your Gender",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Gender</div>
                        <Input
                          placeholder="Enter Your Gender"
                          onChange={(e) => setQualification(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="Nationality"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Nationality",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Nationality</div>
                        <Input
                          placeholder="Please Enter Your Nationality"
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="SignInInput">
                      <Form.Item
                        name="City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your city",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>City</div>
                        <Input
                          placeholder="type your City name"
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="District"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Please enter a  District",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>District</div>
                        <Input
                          placeholder="Your District"
                          // value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="SignInInput">
                      <Form.Item
                        name="value"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your mobile number",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Mobile Number</div>
                        <PhoneInput
                          placeholder="XXXX XXX XXX"
                          defaultCountry="IN"
                          onChange={setValue}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your State",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>State</div>
                        <Input
                          placeholder="Enter Your State"
                          // value={State}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="SignInInput">
                      <Form.Item
                        name="Email"
                        rules={[
                          {
                            required: true,
                            message: "Type your Gender",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Email</div>
                        <Input
                          placeholder="Enter Your Email"
                          onChange={(e) => setQualification(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="continueDivSignInPageLap">
                    <div className="SignInInput">
                      <Form.Item
                        name="Residential Address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Address",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>
                          Residential Address
                        </div>
                        <Input
                          placeholder="Enter Your Address"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="SignInInput">
                      <Form.Item
                        name="Postal Code"
                        rules={[
                          {
                            required: true,
                            message: "Type your Postal Code",
                          },
                        ]}
                      >
                        <div style={{ height: "30px" }}>Postal Code</div>
                        <Input
                          placeholder="Enter Your Postal Code"
                          onChange={(e) => setQualification(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {/* <Navigate to="/InformationFirstStep" />; */}
                    {/* <Link to="/InformationFirstStep"> */}
                    <div style={{ height: "10px" }}>
                      <Form.Item>
                        <Button
                          className="NextButton"
                          type="primary"
                          htmlType="submit"
                          style={{ height: "30px" }}
                          onClick={click}
                        >
                          Next...
                        </Button>{" "}
                      </Form.Item>
                    </div>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
              {/* </div> */}
            </div>
            <div>
              <Link to="/terms">
                <AiOutlineLeft />
              </Link>
              <Link to="/InformationFirstStep">
                <AiOutlineRight />
              </Link>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default BookYourFreeTrial;

{
  /* // // FaWhatsapp
// // MdOutlineMail
// // AiFillFacebook
// // AiOutlineInstagram
// // BsInstagram
// // AiFillInstagram
// // BsFillTelephoneFill
// import React from "react"; */
}
