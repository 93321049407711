import Anshab from '../../../Assets/Anshab.jpeg.jpg'
export const ClientsInfo = [
  {
    id: 1,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Anshab",
    description:
      "At Semikolen, I immersed myself in development intricacies from hands-on experiences. The comprehensive learning and meaningful connections shaped my growth.",
  },
  {
    id: 2,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Sajeer",
    designation:"Senior software engineer",
    description:"If you want a javaScript developer who truly cares about your project, look no further than Jitendra. He was professional, responsive, and always went the extra mile to ensure success.",
  },
  {
    id: 3,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Shahin",
    description:
      "Semikolen transformed my tech journey from hardware to coding mastery, equipping me with programming skills. It's where potential meets expertise.",
  },
  {
    id: 4,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Nasim",
    description:
      "He turned my vision into a reality, creating a website that exceeded my expectations. he has deep expertise in javaScript and seamlessly integrated complex features.",
  },
  {
    id: 4,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Jis",
    description:
      "He turned my vision into a reality, creating a website that exceeded my expectations. he has deep expertise in javaScript and seamlessly integrated complex features.",
  },
  {
    id: 4,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Soudha",
    description:
      "He turned my vision into a reality, creating a website that exceeded my expectations. he has deep expertise in javaScript and seamlessly integrated complex features.",
  },
  {
    id: 4,
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpoQaaw13BKAmYv1iRPzkz9AkM0ZskCqK_g&usqp=CAU",
    title: "Yaseen",
    description:
      "I arrived at semicolons without any prior knowledge of coding. Now, I can design websites using React. I am aware of the processes involved in website deployment",
  },
];