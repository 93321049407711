import React from 'react'
import './Blogsecond.css'


function Blogsecond() {
  return (
    <div className='SecondPageStart'>
        <div className='BlogBoxesDiv'>
            <div className='BlogBoxes'>
                <div className='BlogBox'>
                    <div className='picture-div'>
                    </div>
                    <div className='BlogBoxHeader'>
                    Are you sitting idle and waiting for
                    your true calling?
                    </div>
                    <div className='BlogBoxTextes'>
                    it's time to start from where you stopped 
                    and transform your career with software 
                    development.At our software development
                    education organization,we beleive that
                    anyone can learn to code, regardless of 
                    their background or experience.

                    </div>

                </div>
                <div className='BlogBox'>
                <div className='picture-div2'>
                </div>
                <div className='BlogBoxHeader'>
                    Are you sitting idle and waiting for
                    your true calling?
                    </div>
                    <div className='BlogBoxTextes'>
                    it's time to start from where you stopped 
                    and transform your career with software 
                    development.At our software development
                    education organization,we beleive that
                    anyone can learn to code, regardless of 
                    their background or experience.

                    </div>

                </div>
                <div className='BlogBox'>
                <div className='picture-div3'>
                </div>
                <div className='BlogBoxHeader'>
                    Are you sitting idle and waiting for
                    your true calling?
                    </div>
                    <div className='BlogBoxTextes'>
                    it's time to start from where you stopped 
                    and transform your career with software 
                    development.At our software development
                    education organization,we beleive that
                    anyone can learn to code, regardless of 
                    their background or experience.

                    </div>

                </div>
                <div className='BlogBox'>
                <div className='picture-div4'>
                </div>
                <div className='BlogBoxHeader'>
                    Are you sitting idle and waiting for
                    your true calling?
                    </div>
                    <div className='BlogBoxTextes'>
                    it's time to start from where you stopped 
                    and transform your career with software 
                    development.At our software development
                    education organization,we beleive that
                    anyone can learn to code, regardless of 
                    their background or experience.

                    </div>

                </div>

            </div>
        </div>
        <div className='AreaAddDiv'>
            <div className='AreaAdd'>
                Area For Ad
            </div>

        </div>
    </div>
  )
}

export default Blogsecond
