import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import "./styles.scss";
import NavbarContent from "../Navbar";

const ContactUs = () => {
  const { TextArea } = Input;
  return (
    <div className="Contact-container" id="contactUsSection">
      <NavbarContent/>
      <div className="Contact-header">
        <div className="Contact-title">Contact Us</div>
        <div className="Contact-desc">
          Feel free to contact us. Our experts are happy to assist you for your
          doubts and enquiries
        </div>
      </div>
      <div className="contactForm-container">
        <Form layout="vertical">
          <Row gutter={[25, 16]}>
            <Col md={24} sm={24} lg={7} xs={24}>
              <Form.Item
                name="firstname"
                label={<div className="custom-formLabel">First Name</div>}
              >
                <Input style={{ height: "35px" }} />
              </Form.Item>
              <Form.Item
                name="lastname"
                label={<div className="custom-formLabel">Last Name</div>}
              >
                <Input style={{ height: "35px", width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="email"
                label={<div className="custom-formLabel">Email</div>}
              >
                <Input style={{ height: "35px" }} />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} lg={7} xs={24}>
              <Form.Item
                name="phone"
                label={<div className="custom-formLabel">Phone</div>}
              >
                <Input style={{ height: "35px" }} />
              </Form.Item>
              <Form.Item
                name="companyname"
                label={<div className="custom-formLabel">Company Name</div>}
              >
                <Input style={{ height: "35px" }} />
              </Form.Item>
              <Form.Item
                name="country"
                label={<div className="custom-formLabel">Country</div>}
              >
                <Input style={{ height: "35px" }} />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} lg={10} xs={24}>
              <Form.Item
                name="note"
                label={
                  <div className="custom-formLabel">
                    Give a simple breif about your enquiries
                  </div>
                }
              >
                <TextArea style={{ height: "213px", width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={14}>
              <div className="formBottom-txt">
                <div>
                  Bairuhatech will only use your information to provide the
                  products and services you <br /> requested from us. We may
                  also send you other offerings that may be of interest to you.
                </div>
                <Form.Item name="agree" className="agree-container">
                  <Checkbox style={{ marginRight: "12px" }} />
                  <span>
                    I agree to receive other communications from Bairuhatech.
                  </span>
                </Form.Item>
              </div>
            </Col>
            <Col md={10}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="contactSubmit-btn"
                >
                  Get Started
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;
