import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Select, Input, Checkbox, Button, Form, Upload, Radio } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import ImgCrop from "antd-img-crop";

const InformationFirstStep = (props) => {
  const [selectedState, setSelectedState] = useState(null);
  const [study, setStudy] = useState();
  const [name, setName] = useState();
  const [qualification, setQualification] = useState();
  const [Country, setCountry] = useState();
  const [City, setCity] = useState();
  const [email, setEmail] = useState();
  const [value, setValue] = useState();

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const mmm = () => {
    console.log(props, "========================<<<<<<<<<<<<<<");
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "malayalam", label: "Malayalam" },
    { value: "hindi", label: "Hindi" },
    // Add more languages as needed
  ];

  return (
    <div>
      <div className="AppLap d-none d-lg-block w-100">
        <div className="headerSignIn">
          <h2>Join with us...</h2>
          <h6>
            Submit your queries here and we <br />
            will get to you as soon as
            <br />
            possible
          </h6>
        </div>
        <main className="ContainerLap d-lg-flex h-100 w-100">
          <section className="servicesSignInLap">
            <div className="servicesMainDivSignInLap">
              <div className="servicesDivSignInLap">
                <div>
                  <Form>
                    <div className="continueDivSignInPageLap">
                      <div className="SignInInput">
                        <Form.Item
                          name="Relevent Qualification"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Passed Year",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>
                            Relevent Qualification
                          </div>
                          <Input
                            placeholder="Enter Your Passed Year"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="SignInInput">
                        <Form.Item
                          name="Course Name"
                          rules={[
                            {
                              required: true,
                              message: "Type your Course Name",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>Course Name</div>
                          <Input
                            placeholder="Enter Your Course Name"
                            onChange={(e) => setQualification(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="continueDivSignInPageLap">
                      <div className="SignInInput">
                        <Form.Item
                          name="Highest Qualification"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Highest Qualification",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>
                            Highest Qualification{" "}
                          </div>
                          <Input
                            placeholder="Enter Your  Highest Qualification   "
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="SignInInput">
                        <Form.Item
                          name="Language"
                          rules={[
                            {
                              required: true,
                              message: "Type your Language",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>Language</div>
                          <Select
                            placeholder="Select Your Language"
                            onChange={(value) => setQualification(value)}
                          >
                            {languageOptions.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="continueDivSignInPageLap">
                      <div className="SignInInput">
                        <Form.Item
                          name="Year Of Complete"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Year Of Complete",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>
                            Year Of Complete{" "}
                          </div>
                          <Input
                            placeholder="Enter Your Year Complete"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="SignInInput">
                        <Form.Item
                          name="Course Name"
                          rules={[
                            {
                              required: true,
                              message: "Type your Course Name",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>Course Name</div>
                          <Input
                            placeholder="Enter Your Course Name"
                            onChange={(e) => setQualification(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="continueDivSignInPageLap">
                      <div>
                        <Form.Item
                          name="Prefered Batch"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Prefered Batch",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>Prefered Batch</div>
                          <Input
                            placeholder="Please Enter Your Prefered Batch"
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="SignInInput">
                        <Form.Item
                          name="Class"
                          rules={[
                            {
                              required: true,
                              message: "Please select online or offline",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => setQualification(e.target.value)}
                          >
                            {/* SElect */}
                            <Radio value="online">Online</Radio>
                            <Radio value="offline">Offline</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="continueDivSignInPageLap">
                      <div>
                        <Form.Item
                          name="Start Date"
                          rules={[
                            {
                              required: true,
                              type: "Start Date",
                              message: "Please enter a valid date",
                            },
                          ]}
                        >
                          <div style={{ height: "30px" }}>Start Date</div>
                          <Input
                            placeholder="Your Start Date"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div
                        className="SignInInput"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div>
                          <ImgCrop rotationSlider>
                            <Upload
                              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChange}
                              onPreview={onPreview}
                            >
                              {fileList.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div
                style={{
                  width: "65%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div style={{ height: "10px" }}>
                  <Button className="NextButton" style={{ height: "30px" }}>
                    Submit
                  </Button>{" "}
                </div>
                <div onClick={mmm}>mishab</div>
              </div>
            </div>
            <div>
              <Link to="/BookYourFreeTrial">
                <AiOutlineLeft />
              </Link>
              <Link to="/InformationFirstStep">
                <AiOutlineRight />
              </Link>
            </div>
          </section>
        </main>
      </div>
      {/* <div>
        <Link to="/BookYourFreeTrial">
          <AiOutlineLeft />
        </Link>
        <Link to="/InformationSecondStep">
          <AiOutlineRight />
        </Link>
      </div> */}
    </div>
  );
};

export default InformationFirstStep;
