import React from 'react'
import CommentSection from '../Confiq/CommentBox'
import LoveButton from '../Confiq/Lovebutton'
import ShareButton from '../Confiq/ShareButton'
import './BlogFourth.css'




function Blogfourth() {
  return (
    <div className='BlogFourth-first'>
        <div className='BeginningImageText'>
            <div className='HeaderImageFourth'>
            </div>
            <div className='HeaderImageTextsFourth'>
                <div className='FourthFirstHeadingBlog'>SPACE</div>
                <div>Space is the vast, three-dimensional expanse that exists beyond 
                    the Earth's atmosphere, where there is no air, sound, or gravity in the 
                    same way we experience it on Earth. It is commonly referred to as the universe, 
                    which encompasses everything that exists, including stars, planets, galaxies, and 
                    all other forms of matter and energy.
                    Space is infinite and expanding, meaning that there is no known boundary 
                    or edge to it. Scientists believe that the universe began with the Big Bang, 
                    a massive explosion that occurred around 13.8 billion years ago, which created
                    all matter and energy in the universe. Since then, the universe has been expanding
                    and cooling, leading to the formation of galaxies and stars.
                    
                </div>
                <div className='FourthLikeShareComment'>

                     <LoveButton/>
                <div className='Fourth-comment-div'>
                    <CommentSection/>
                </div>
                <div className='Fourth-share-div'>
                <ShareButton/>
                </div>
                
                </div>

            </div>

        </div>
        <div className='FourthHeaderLinkingTexts'>
            <div className='FourthTextsDiv'>
            Physical Space                
            </div>
            <div className='FourthTextsDiv'>
            Outer Space                

            </div>
            <div className='FourthTextsDiv'>
            Psychological space                
            </div>
            <div className='FourthTextsDiv'>
            Social Space                

            </div>


            </div> 
           

    </div>
  )
}

export default Blogfourth;